import React, { useState, useEffect, useRef } from "react";
import { GLView } from "expo-gl";
import { View, Text, TextInput, TouchableOpacity } from "react-native";
import Expo2DContext from "expo-2d-context";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import i18n from "../components/i18n";
import { WebView } from "react-native-webview";

const Captcha = (props) => {
  const mounted = useRef(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const [code, setCode] = useState("");
  const [key, setKey] = useState(1)


  useEffect(() => {
    mounted.current = true;
    console.log("render");
    //genCaptcha()

    return () => {
      mounted.current = false;
    };
  }, []);

  const refreshCaptcha = () => {
    let newKey = key + 1

    setKey(newKey)

    props.returnStatus(false);
  };

  const inputCode = (code) => {
    setCode(code);
    if (code == captchaCode) {
      props.returnStatus(true);
    } else {
      props.returnStatus(false);
    }
  };

  return (
    <View>
      <View className="relative flex flex-row items-center">
        <Text className="absolute text-lg text-brandgold italic tracking-[0.1em] font-Crimson p-2">
          
        </Text>

        <WebView key={key}
          source={{ uri: "/recaptcha.html" }}
          style={{ marginTop: 0, width: 130, height: 50 }}
          scalesPageToFit={false}
          onMessage={(event) => {
            if (event.nativeEvent.data.code) {
              setCaptchaCode(event.nativeEvent.data.code)
            }
          }}
        />

        <TouchableOpacity
          className="flex flex-row ml-1"
          onPress={() => {
            refreshCaptcha();
          }}
        >
          <FontAwesomeIcon
            name="refresh"
            className="text-sm text-brandgold"
          ></FontAwesomeIcon>
          <Text className="text-brandgold text-sm font-PingFangSC">
            {" "}
            {i18n.t("signUp.refresh")}
          </Text>
        </TouchableOpacity>
      </View>
      <TextInput
        placeholder={i18n.t("signUp.captcha")}
        className="h-9 w-48 bg-[#F2F3F5] color-[#121212] px-4"
        value={code}
        onChangeText={inputCode}
      ></TextInput>
    </View>
  );
};
export default Captcha;
