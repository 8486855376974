export default {
  login: {
    login: "登 錄",
    email: "電子郵箱",
    mobile: "手機號碼",
    mobile2: "手機號碼",
    password: "密碼",
    errMsg1: "請輸入電子郵箱和密碼",
    errMsg2: "請輸入手機號碼和密碼",
    errMsg3: "您的帳戶不在此地區",
    errMsg4: "您的登錄或密碼不正確",
    errMsg5: "您的輸入手機號碼或密碼不正確",
    errMsg6: "您的註冊還未完成，請重新登記及完成付款步驟",
    forgotpw: "忘記密碼？",
    register: "注 冊",
    txt: "還不是御寶會員？查看",
    txt_2: "會員禮遇",
  },
  header: {
    home: "首頁",
    eStore: "電子商城",
    PRivileges: "會員禮遇",
    register: "註冊",
    highlights: "亮點",
    faq: "常見問題",
    tnp: "條款和隱私",
    friendReferral: "朋友推薦",
    profile: "會員檔案",
  },
  footer: {
    aboutUs: "關於御寶",
    eStore: "電子商城",
    faq: "常見問題",
    tnp: "條款與隱私",
    pr: "參與分店",
    profile: "會員檔案",
    contactUs: "聯繫我們",
    copyright: "2023©御寶飲食集團版權所有",
  },
  signUp: {
    signUp: "註冊會員",
    title: "註冊御寶忠誠計劃會員",
    mustFill: "“*” 為必填項",
    step1: "賬戶資料",
    step2: "安全信息",
    step3: "信息訂閱",
    step4: "推薦人",
    salutation: "稱謂",
    salutation_opt1: "請選擇您的稱謂",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "請選擇稱謂",
    firstname: "名字",
    firstname_errMsg: "請輸入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "請輸入您的姓氏",
    birth: "出生日期",
    birth_errMsg: "請選擇出生日期",
    birth_errMsg2: "您必須年滿18歲",
    mobile: "手機號碼",
    mobile_errMsg: "請輸入有效電話",
    mobile_errMsg2: "手機號碼已註冊",
    emailAddress: "電子郵箱",
    emailAddress_errMsg: "請輸入有效的電子郵箱",
    emailAddress_errMsg2: "電子郵箱已註冊",
    confirmEmailAddress: "電子郵箱確認",
    confirmEmailAddress_errMsg: "請確認電子郵箱",
    gender: "性別",
    gender_opt1: "請選擇您的性別",
    gender_opt2: "男",
    gender_opt3: "女",
    gender_errMsg: "請選擇性別",
    language_preference: "語言選項",
    language_preference_opt1: "請選擇您的語言選項",
    language_preference_opt2: "English",
    language_preference_opt3: "繁體中文",
    language_preference_errMsg: "請選擇語言選項",
    password_format: "密碼至少為8個字符，其中需包含大小字母、數字和一個特殊字符 (?!@$%&*)",
    password: "*密碼",
    password_errMsg: "請輸入密碼",
    confirm_password: "*密碼確認",
    confirm_password_errMsg: "請再次輸入密碼",
    step3_consent_opt: "通过勾选以下的方框，我同意御宝餐饮（国际）有限公司及其子公司（“Imperial Treasure”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方（统称为“关联方”，从而使我能够享受御宝俱乐部会员资格的权益，包括促销、特别优惠、折扣和会员资格， 以便本人可以享受我作为御宝会会员资格所产生的利益，包括因御宝与香港境内外服务提供商的关系而产生的利益。我承认御宝将分享此类营销活动的经济利益。",
    step3_consent_txt: "我同意根据其隐私声明*使用我的以下个人数据来营销 Imperial Treasure 的产品/服务：-\n• 姓名\n• 手机号码\n• 电邮地址\n• 出生日期",
    tnc_errMsg: "請接受條款和條件以繼續下一步",
    step3_title: "點擊下方按鈕，即表示您同意御寶、我司關聯方、相關實體以及代表我司行事的第三方（“業務合作夥伴”）可收集、使用及披露您在會員門戶網站上提供的個人信息。我司將向您提供由您同意接收的促銷、特別優惠，促銷、特別優惠可能由我司的業務合作夥伴直接發送，我司可能會向我司的業務合作夥伴提供您的個人信息。",
    step3_q: "我希望收到来自ITRG的营销/推广信息与材料。",
    step3_q_opt1: "通过电子邮件",
    step3_q_opt2: "通过电话",
    step3_q1: "*请勾选提供的方框以表明您同意每一项。",
    step3_q2: "除非征得您的同意，否则御宝不会将您的个人资料用于上述用途。如果您改变主意，您随时可以在我们的会员门户网站 https://members.imperialtreasure.com.hk 上免费撤销您的同意。",
    step4_q_err: "請選擇如何得知御寶忠誠計劃",
    step4_q: "您是如何得知御寶忠誠計劃？",
    step4_q_opt1: "請選擇",
    step4_q_opt2: "九龍尖沙咀, 御寶軒",
    step4_q_opt3: "皇冠假日酒店, 御寶軒",
    step4_q_opt16: "口碑介紹",
    step4_q_opt17: "社交媒體",
    step4_q_opt18: "其他",
    promo_code: "優惠碼",
    promo_code_input_msg: "請輸入有效的促銷代碼（如有)",
    promo_code_err_msg: "請輸入有效的促銷代碼",
    promo_code_re_msg: "促銷代碼有效!",
    total: "總共：",
    refresh: "刷新",
    captcha: "驗證碼",
    captcha_errMsg: "驗證碼不正確",
    cancel: "取消",
    next: "下一步",
    back: "上一步",
    submitForm: "立即付款",
  },
  aboutUs: {
    aboutUs: "關於御寶",
    txt1: "御寶飲食集團",
    txt2: "御寶飲食集團創建於 2004 年。中華美食源遠流長，御寶尊崇博大精深的美食文化，矢志繼承這寶貴遺產。因此御寶一直堅持以道地的烹飪手法，為食客奉上美味豐盛的佳餚。加之御寶以服務至上，細緻貼心，宗旨是提供舒適的用餐體驗。",
    txt3: "迄今為此，御寶集團旗下囊括多過二十間店鋪，各色美食，應有盡有。在經驗領導及顧客支持下，御寶更榮獲超過五十不同獎項包括：米其林指南上海兩顆星 （2017 - 2023） 御寶軒 （益豐）, 米其林指南廣州兩顆星 （2020 - 2022）,  一顆星（2019） 御寶軒 （天匯廣場）,米其林指南新加坡一顆星（2017 - 2019，2021-2022） 御寶閣潮州酒家 （愛雍·烏節）,  米其林指南香港澳門一顆星（2018 - 2022） 御寶軒 (尖沙咀), 米其林指南廣州一顆星（2021- 2022） 御寶閣潮州酒家 （太古匯）, 米其林指南廣州米其林餐盤獲榮譽獎（2018， 2020）, 米其林指南法國米其林餐盤獲榮譽獎（2020）, 米其林指南新加坡米其林餐盤獲榮譽獎（2018，2019）,  米其林指南英國和愛爾蘭米其林餐盤獲榮譽獎（2019）, 美團點評黑珍珠餐廳指南二鑽(2018), 全球最佳中餐廳，由莊布忠頒發, 亞洲 “50家最佳” 餐廳等諸多美譽（2013 - 2015）。",
    txt4: "御宝在新加坡，上海，广州，香港，仁川，伦敦和巴黎设有分店。",
  },
  faqs: {
    faqs: "FAQs",
    general: "常見問題",
    general_q1: "什麼是御寶會員計劃？",
    general_q1_opt1: "御寶會員計劃是一個無卡的獎勵項目，當會員在指定分店用餐時，即可享受專屬禮遇和獨家優惠。",
    general_q2: "如何成為御寶會員？ ",
    general_q2_opt1: "受邀顧客可以到https://members.imperialtreasure.com.hk 進行網上註冊。",
    general_q3: "哪些是指定分店? ",
    general_q3_opt2: "御寶軒",
    general_q3_opt2_1: "香港九龍尖沙咀北京道 1 號 10 樓 |電話： (852) 2613 9800",
    general_q3_opt2_2: "香港銅鑼灣禮頓道8號 , 皇冠假日酒店2樓 |電話：(852) 2663 9919",
    general_q4: "會籍的有效期為多久？ ",
    general_q4_opt1: "會籍由註冊日期起的 1 年內有效。例如：如果您在 2023年 7 月 1 日註冊，會籍則將於 2024 年 6 月 30 日到期。",
    general_q5: "可在哪裡查詢我的會員身份到期日？ ",
    general_q5_opt1: "您可登錄會員專屬網頁https://members.imperialtreasure.com.hk，查詢會員身份的到期日。",
    general_q6: "申請成為會員的最低年齡是多少？ ",
    general_q6_opt1: "顧客需要年滿18歲才能申請成為會員。",
    general_q7: "為什麼註冊時需要我的個人資料？ ",
    general_q7_opt1: "您的個人資料是用作驗證會員的憑證。除此之外，您還將收到最新的會員促銷、獎勵、活動邀請和更多相關信息。",
    general_q8: "如何更新我的個人資料？",
    general_q8_opt1: "可通過會員專屬網頁https://members.imperialtreasure.com.hk 登錄賬戶，查看或更新您的個人資料。",
    general_q9: "如何查詢我的電子禮券和其它獎勵？",
    general_q9_opt1: "可通過會員專屬網頁https://members.imperialtreasure.com.hk 登錄賬戶，查詢您的會員優惠及其它獎勵。",
    general_q10: "如果我忘記了密碼怎麼辦？ ",
    general_q10_opt1: "請登錄https://members.imperialtreasure.com.hk 並點擊“忘記密碼”，按照屏幕上的提示重置密碼。",
    general_q11: "如果我對會員賬戶有疑問怎麼辦？",
    general_q11_opt1: "如果您對您的會員賬戶有任何疑問，請發送電子郵件至hk.club@imperialtreasure.com",
    general_q12: "會員身份是否可以轉讓？",
    general_q12_opt1: "不可以。會員身份不宜轉讓。",
    general_q13: "如何終止我的會員身份? ",
    general_q13_opt1: "會員可以選擇在失效日期之前的任何時間通過電郵至 hk.club@imperialtreasure.com 聯繫我們以終止會員資格。請注意，在終止時將不會退款和/或提前發放任何未使用的電子禮券。另一種方式是讓您的會員資格自動失效，所有未使用的電子禮券將被視為放棄使用。",
    membershipTypes: "會員等級",
    membershipTypes_q1: "會員有哪幾種級別？",
    membershipTypes_q1_opt1: "會員級別共有三種，分別為：銀，金和鑽石。",
    membershipTypes_q2: "如何成為銀，金或鑽石會員？",
    membershipTypes_q2_opt1: "受邀顧客在網上註冊後， 即成為銀會員。",
    membershipTypes_q2_opt2: "在會員有效期內累積消費達港幣 68,000*元 或以上，即自動成為金會員。",
    membershipTypes_q2_opt3: "在會員有效期內累積消費達港幣 168,000* 元或以上，即自動成為鑽石會員。",
    membershipTypes_q2_opt4: "（*消費金額不包含10%服務費。）",
    membershipTypes_q3: "如何保留我的會籍？",
    membershipTypes_q3_opt1: "要維持金等級，只需在會員有效期內累積消費達港幣 68,000*元或以上，即可保留會籍。",
    membershipTypes_q3_opt2: "要維持鑽石等級，只需在會員有效期內累積消費港幣 168,000*元或以上，即可保留會籍。",
    membershipTypes_q3_opt3: "（*消費金額不包含10%服務費。）",
    membership: "會員專屬禮遇&獎勵",
    membership_q1: " 會員專屬禮遇包括什麼？",
    complimentary_treats: "會員獎勵",
    sliver: "銀",
    gold: "金",
    diamond: "鑽石",
    gold_note: "(累積消費達港幣68,000* 元或以上)",
    diamond_note: "(累積消費達港幣168,000* 元或以上)",
    type_of_privileges_1_1: "脆皮乳鴿（一份三隻）",
    type_of_privileges_1_2: "-",
    type_of_privileges_1_3: "享有",
    type_of_privileges_1_4: "享有",
    type_of_privileges_2_1: "燒鴨（一隻）",
    type_of_privileges_2_2: "-",
    type_of_privileges_2_3: "享有",
    type_of_privileges_2_4: "享有",
    type_of_privileges_3_1: "烤乳豬（一隻）",
    type_of_privileges_3_2: "-",
    type_of_privileges_3_3: "-",
    type_of_privileges_3_4: "享有",
    type_of_privileges_4_1: "紅酒（一支）",
    type_of_privileges_4_2: "-",
    type_of_privileges_4_3: "享有",
    type_of_privileges_4_4: "享有",
    type_of_privileges_5_1: "香檳（一支）",
    type_of_privileges_5_2: "-",
    type_of_privileges_5_3: "-",
    type_of_privileges_5_4: "享有",
    type_of_privileges_6_1: "港幣1000元電子現金禮券",
    type_of_privileges_6_2: "-",
    type_of_privileges_6_3: "享有",
    type_of_privileges_6_4: "享有",
    type_of_privileges_7_1: "港幣2000元電子現金禮券",
    type_of_privileges_7_2: "-",
    type_of_privileges_7_3: "-",
    type_of_privileges_7_4: "享有",
    membership_q2: "如何知道我的會員優惠是否到期？",
    membership_q2_opt1: "要查閱各項禮遇及獎勵的有效日期，只需登錄會員專屬網頁https://members.imperialtreasure.com.hk, 查詢“禮券”的詳細信息。",
    membership_q3: "如何证明自己是御宝会会员？",
    membership_q3_opt1: "要确定您的会员身份，只需向我们的服务人员提供您的手机号码即可。",
    membership_q4: "如何領取贈送禮品？ ",
    membership_q4_opt1: "燒鴨, 烤乳豬和脆皮乳鴿能在御寶軒領取，需要提前一天預訂。會員必須親自到場（需要身份證明）才能兌換任何會員特權。",
    membership_q5: "作為一個企業客戶，公司折扣能否與會員優惠並用？",
    membership_q5_opt1: "不可以，企業客戶只能在公司折扣或會員優惠中二者選其一。",
    membership_q6: "會員在御寶的海外分店享有同樣優惠嗎？",
    membership_q6_opt1: "會員優惠目前僅限香港指定餐廳。對於海外會員的更多信息，請關注我們以獲得最新資訊。",
    membership_q7: "會員卡是否可以轉讓？",
    membership_q7_opt1: "不可以。會員卡不宜轉讓。",
    earning: "賺取與兌換積分",
    earning_q1: "如何賺取積分？",
    earning_q1_opt1: "在指定門店每消費HKD10*，便可賺取積分。會員本人必須在場以驗證身份，過往消費的單據一律被視作無效。使用多個會員賬戶和拆分賬單以進行積分賺取，是絕對不被允許的，每桌客人的每張單據，只能算進一個會員賬戶中。 （*不包含10%服務費、電子現金禮券和季節性產品）",
    earning_q2: "如何使用積分？",
    earning_q2_opt1: "每賺取了250積分後，會員將在次日凌晨自動收到一張HKD250的電子禮券*（只限會員有效期內使用）。會員可在任何指定門店用餐時使用電子禮券，會員本人必須在場以驗證身份，積分將會在賬單結算後自動存入會員賬戶。\n(*系統每天定時自動轉換積分為電子現金禮券)",
    earning_q3: "我的積分會過期嗎？",
    earning_q3_opt1: "會的。一旦會員資格到期，積分也將過期，未使用的積分將有3個月的寬限期，會員必須在寬限期內將剩餘積分轉換為電子現金禮券，所有未被使用的積分將被清零。",
    earning_q4: "我的積分會在消費後即時顯示出來嗎？",
    earning_q4_opt1: "會的。除非在網絡故障時，才會有所延遲；但正常情況下系統都能即時顯示出賺取的積分。",
    earning_q5: "可以使用多個會員賬戶賺取和兌換積分嗎？",
    earning_q5_opt1: "不可以。",
    earning_q6: "可以拆分賬單以賺取積分或兌換積分嗎？",
    earning_q6_opt1: "不可以。",
    earning_q7: "如果我不在場，我的家人或朋友可以使用我的會員卡賺取或兌換禮遇嗎？",
    earning_q7_opt1: "不可以，會員本人必須在場享受會員禮遇。",
    earning_q8: "可以通過第三方外賣平台賺取points或使用電子禮券嗎?",
    earning_q8_opt1: "不可以。",
    renewal: "會員身份更新",
    renewal_q1: "如何更新我的會員身份？",
    renewal_q1_opt1: "只需在會員年份內累積消費達HKD68,000*或以上（金會員）或HKD 168,000*或以上（鑽石會員），即自動更新會員身份。\n（*消費金額不包含10%服務費。）",
  },
  tnc: {
    title: "Terms And Privacy",
    consent_1: "Consent wording on sign-up page",
    consent_1_1: "□ I acknowledge that I have read and agree to the Terms and Conditions",
    consent_1_2: "[link:https://members.imperialtreasure.com.hk/cn/tnc] set forth herein.",
    consent_2: "Opt-in Wording on sign-up page",
    consent_2_1: "□ By checking this box,  I give my consent to Imperial Treasure (International) Company Ltd (“Imperial Treasure”) to collect, use, store and/or disclose the below personal data provided by me in connection with the Imperial Treasure Club membership for direct marketing purposes in order to receive news and information regarding Imperial Treasure’s products and services, including promotions, special offers, discounts and membership offered by Imperial Treasure in accordance with its Privacy Notice. I consent to the use of my personal data below for marketing Imperial Treasure’s products/services in accordance with its Privacy Notice*:–",
    consent_2_2: "□ name",
    consent_2_3: "□ residential address",
    consent_2_4: "□ mobile phone number",
    consent_2_5: "□ email address",
    consent_2_6: "□ date of birth ",
    consent_2_7: "I would like to receive marketing and promotional messages and materials from Imperial Treasure through the following channels*:",
    consent_2_8: "□ By Email    □ By Post     □ By SMS",
    consent_2_9: "*Please tick the box(es) provided to indicate your consent for each item.",
    consent_2_10: "Imperial Treasure will not use your personal data for the aforesaid purposes unless Imperial Treasure has obtained your consent. If you change your mind, you can always, without charge, revoke your consent at our member portal at https://members.imperialtreasure.com.hk.",
    consent_3_1: "□ By checking this box, I give my consent to Imperial Treasure to collect, use, store and/or disclose the below personal data provided by me in connection with the Imperial Treasure Club membership for Imperial Treasure to share my data with any of its associated or related companies or  third parties including restaurant franchisees and restaurant partners receiving Imperial Treasure’s management support, (collectively, “Related Parties”) for direct marketing purposes in order to receive news and information regarding the Related Parties’ products and services, including promotions, special offers and discounts offered by the Related Parties, so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from Imperial Treasure’s relationship with service providers within and outside of Hong Kong. I acknowledge that Imperial Treasure will share the economic benefit of such marketing activities.",
    consent_3_2: "I consent to the provision of my personal data below by Imperial Treasure to any Related Parties for the aforementioned purposes in accordance with its Privacy Notice*:–",
    consent_3_3: "□ name",
    consent_3_4: "□ residential address",
    consent_3_5: "□ email address",
    consent_3_6: "I would like to receive marketing and promotional messages and materials from the Related Parties through the following channels*:",
    consent_3_7: "□ By Email    □ By Post",
    consent_3_8: "*Please tick the box(es) provided to indicate your consent for each item.",
    consent_3_9: "Imperial Treasure will not provide your personal data to the Related Parties for the aforesaid purposes unless Imperial Treasure has obtained your written consent to such provision. If you change your mind, you can always, without scharge, revoke your consent at our member portal at https://members.imperialtreasure.com.hk.",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure (International) Company Ltd ("Imperial Treasure") would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts. We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time. To become a Member, you must be 18 years of age or above.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: 'If you become a Member, you will need to provide Imperial Treasure with certain personal data (including your name, date of birth and contact details such as email address and mobile number). Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice stated on https://members.imperialtreasure.com.hk (the "Member\'s Portal").',
    membership_1_3: "1.3 Membership Tiers",
    membership_1_3_1: '1.3.1 Membership registration, i-dollars, and Cash e-Vouchers',
    membership_1_3_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_3_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_3_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, tax, Cash e-Vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-Vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_3_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [link:https://members.imperialtreasure.com.hk/cn/tnc]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_2: "1.3.2 Membership Tiers",
    membership_1_3_2_1: "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure in its sole discretion on the membership website [link: members.imperialtreasure.com.hk] for the country in which you signed up.",
    membership_1_3_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon the expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_3_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon the expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_3_2_4: "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier programme rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable notice by email. Please check the membership programme website regularly for updates.",
    membership_1_3_3: "1.3.3 Membership Tier Rewards",
    membership_1_3_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [link: members.imperialtreasure.com.hk]. The validity and terms and conditions of such Rewards are as set out in [link:https://members.imperialtreasure.com.hk/cn/tnc], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability.  Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_4: "1.3.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_3_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made.",
    membership_1_3_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_4: "1.4 Amending personal data and terminating membership",
    membership_1_4_1: "You may amend your personal data or marketing preferences at any time via our Member's Portal. You will need to inform us in writing at hk.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-Vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and unused Cash e-Vouchers will be deemed forfeited.",
    membership_1_5: "1.5 Termination and Cancellation",
    membership_1_5_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”), are governed by and construed in accordance with the laws of Hong Kong. Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of Hong Kong courts.",
    membership_3: "3. Consent",
    membership_3_1: "I would like to join the Imperial Treasure Membership club and I consent you to holding my personal data in accordance with your Privacy Policy.",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure (International) Company Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protecting the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_2: 'This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: "7. What rights do I have".',
    privacy_1_3: 'Maintaining the privacy of our customer\'s information is important to us. We do not disclose rent or sell our customer\'s personal data to third parties (except as described in section "4. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, date of birth and phone number; and",
    privacy_2_1_3: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes:",
    privacy_2_2_1: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_1_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_1_2: "to facilitate your sign up for our membership programme;",
    privacy_2_2_1_3: "to use information you provide to provide products and services you have requested; and",
    privacy_2_2_1_4: "to respond to comments, queries and feedback.",
    privacy_2_2_2: "Where you give us consent:",
    privacy_2_2_2_1: "we provide you with marketing and promotional materials and other information about our products (including promotions and special offers), as well as other products and services provided by our Affiliates and partners (as detailed in section 3 below); and",
    privacy_2_2_2_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_3: "For purposes which are required by law: in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3_1: "The provision of your name, family name, contact number, date of birth, and email address is mandatory such that we can contact you for sharing updates on membership status, points, vouchers and promotions, register you as a user of the Website and sign you up for our membership programme. If the relevant data is not provided, then we will not be able to contact you for sharing updates on membership status, points, vouchers and promotions, register you as a user of the Website and sign you up for our membership programme. All other provision of your information is optional.",
    privacy_2_3_2: "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_4: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_4_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_4_2: "to improve the contents of the Website;",
    privacy_2_4_3: "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you;",
    privacy_2_4_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_4_5: "to obtain statistic data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_5: "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. DIRECT MARKETING",
    privacy_3_1: "Where we have your consent, we may use the personal data you provided at the Member’s Portal for the application as a Member (including your name and contact details such as email address and phone number) for providing marketing materials that you have agreed to receive, including news and information regarding our products and services, including promotions, special offers, discounts and membership offered by us, and we may also share your personal data with our Affiliates and third parties including restaurant franchisees and restaurant partners receiving Imperial Treasure’s management support (together with our Affiliates, “Related Parties”),  for the sending of marketing materials that you have agreed to receive, including news and information regarding the Related Parties’ products and services, including promotions, special offers and discounts offered by the Related Parties in accordance with the Personal Data (Privacy) Ordinance (Cap. 486) (“PDPO”) and this Privacy Notice. Please note that marketing materials may be sent directly by our Related Parties, and we may disclose your personal data to them for that purpose. For the avoidance of doubt, we and our Related Parties may not use personal data provided by you for direct marketing unless we received your consent and/or indication of no objection for such use and such provision. You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, free of charge, at any time. If you do not wish to receive any marketing information from us and/or if you do not wish us to provide your personal data to any third parties for direct marketing purposes, you can opt-out by following the instructions in the communication where this is an electronic message, or by contacting us using the details set out in section: “10. Contact Us” below.",
    privacy_4: "4. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_4_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_4_1: "In order to provide services to you, we may disclose and/or transfer your personal data out of Hong Kong, as described in this Privacy Notice:",
    privacy_4_1_1_1: "We may share your personal data with our Related Parties and loyalty programme vendors for the purposes of: Fulfilling a contract with you, providing customer services and membership services and benefits, facilitating cross-country access of your personal data to allow you to use member privileges or other membership services, facilitating your registration as a user of the Website, and/or marketing of products and services to you as described in section 3 above.",
    privacy_4_1_1_2: "For the purpose of this Privacy Notice, “Affiliate” means an entity that owns or controls, is owned or controlled by or is or under common control or ownership with Imperial Treasure, where control is defined as the possession, direct or indirect, of the power to direct or cause the direction of the management and policies of an entity, whether through ownership of voting securities, by contract or otherwise.",
    privacy_4_1_2: "We may permit certain trusted third parties, for example, Google Analytics, to access your personal data in connection with their performance of services to maintain and operate the Website and certain features on the Website. For example, we may use third parties to host the Website; operate various features available on the Website; send emails; analyze data; provide search results and links and assist in fulfilling your orders and may share personal data or other information with our parent, subsidiaries, divisions, and Affiliates.",
    privacy_4_1_3: "We may employ a third-party service to collect the above-mentioned information, including personal data, and use their tracking services on our behalf. However, this information is strictly confidential and will not be shared with others.",
    privacy_4_1_4: "We may disclose personal data as an asset in connection with a proposed or actual merger or sale (including any disclosures made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.",
    privacy_4_1_5: "We reserve the right to disclose information, including personal data, in order to comply with a subpoena, court order, administrative or governmental order, or any other requirement of law, or when we, in our sole discretion, believe it is necessary in order to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk reduction. We may also disclose or transfer personal data as otherwise permitted or required by law.",
    privacy_4_1_6: "We may disclose aggregated and anonymized data to third parties.",
    privacy_4_2: "Your personal data may be transferred out of Hong Kong from time to time. Such transfers will be carried out in accordance with the PDPO. Third parties, with the exception of Hong Kong public agencies, are subject to data protection obligations and may only use your personal data to perform the necessary functions.",
    privacy_5: "5. HOW WE PROTECT YOUR INFORMATION",
    privacy_5_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure, and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_6: "6. LINKS TO THIRD PARTY SITES",
    privacy_6_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_6_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_7: "7. WHAT RIGHTS DO I HAVE",
    privacy_7_1: "You have the right to ask us for a copy of your personal data and to correct any inaccurate data about you.",
    privacy_7_2: "In addition, wherever we rely on your consent, you will always be able to withdraw that consent.",
    privacy_7_3: "These rights may be limited, for example if fulfilling your request would reveal personal data about another person, where they would infringe the rights of a third party (including our rights). Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_7_4: "If you are based in Hong Kong and have unresolved concerns, you have the right to complain to the Privacy Commissioner of Personal Data.",
    privacy_7_5: 'To exercise any of these rights, you can get in touch with us - by contacting our Data Protection Manager (See section "10. Contact us" below). To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_7_6: 'Please allow forty (40) working days for your request to be processed and in any event, we will process your requests as soon as possible in accordance with legal requirements.',
    privacy_8: "8. RETENTION OF INFORMATION",
    privacy_8_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_8_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_8_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_8_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_9: "9. PRIVACY POLICY CHANGES",
    privacy_9_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email or obtain your consent as may be required by applicable law. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_10: "10. CONTACT US",
    privacy_10_1: "If you have any questions about this Privacy Notice, or to contact our Data Protection Manager, please contact us at hk.club@imperialtreasure.com.",
  },
  outlets: {
    outlets: "餐廳",
    restaurant_name: "餐廳名稱",
    address: "住址",
    contact: "聯繫",
    email: "電子郵件",
    outlets_1_1: "御寶軒",
    outlets_1_2: "香港尖沙咀北京道1號10樓",
    outlets_1_3: "(852) 2613 9800",
    outlets_1_4: "finechinese_onepeking@imperialtreasure.com",
    outlets_2_1: "御寶軒",
    outlets_2_2: "香港銅鑼灣禮頓道8號皇冠假日酒店2樓",
    outlets_2_3: "(852) 2663 9919",
    outlets_2_4: "finechinese_crowneplaza@imperialtreasure.com",
  },
  privilege: {
    title: "特權",
    privilege: "會員優惠",
    privelege_txt: "每消费港幣10元# 可赚取 i$1 \n累计 i$250 以换取港幣250元现金电子礼券",
    silver: "銀",
    silver_1: "僅限邀請",
    gold: "金",
    gold_1: "凡以會員身份一年內累積消費達港幣 68,000 # 元或以上， 將升級為金卡會員並獲得以下獎:",
    gold_2: "免費更新會籍",
    gold_3: "免費贈品：\n脆皮乳鴿一份（三隻） \n燒鴨一隻 \n紅酒一瓶 \n港幣 1000 元現金電子禮券",
    diamond: "鑽石",
    diamond_1: "凡以會員身份一年內累積消費達港幣168,000 # 元或以上， 將升級為鑽石會員並獲得以下獎勵：享有金卡禮遇和以下更多:",
    diamond_2: "所有金卡福利以及：",
    diamond_3: "免費更新會籍",
    diamond_4: "免費贈品：\n乳豬一隻 \n香檳一瓶 \n港幣 2000 元現金電子禮券",
    service_charge_note: "*不包括 10%服務費（如有），附帶其它條款及細則",
    sign_up_now: "立即註冊",
  },
  resetPassword: {
    title: "忘記密碼",
    reset_password: "重置密碼",
    email: "*請輸入電子郵箱",
    email_input: "請輸入電子郵箱",
    email_errMsg: "請輸入有效的電子郵箱",
    mobile: "*請輸入手機號碼",
    mobile_errMsg: "請輸入有效電話",
    new_password: "*新密碼",
    new_password_errMsg1: "請輸入密碼",
    new_password_errMsg2: "密碼必須至少包含8個字母數字字符，並且至少包含一個大寫字母和一個特殊字符（?!@$%&*）",
    confirm_password: "*確認新密碼",
    confirm_password_errMsg: "請確認密碼",
    reset_password2: "前去重置密碼",
    has_been_reset: "密碼已重置！ ",
    login: "登錄",
  },
  forgetPassword: {
    title: "忘記密碼",
    reset_password: "重置密碼",
    forgot_password: "忘記密碼？",
    reset_pw_method: "請選擇密碼重置方式",
    by_mobile: "通過手機",
    by_email: "通過電子郵件",
    email: "*請輸入電子郵箱",
    email_input: "請輸入電子郵箱",
    email_errMsg: "請輸入有效的電子郵箱",
    mobile: "*請輸入手機號碼",
    mobile_errMsg: "請輸入有效電話",
    reset_password2: "前去重置密碼",
    reset_password_sent_email: "重置密碼鏈接已發送到您的電子郵箱。請注意查收！",
    reset_password_sent_mobile: "重置密碼鏈接已發送到您的手機短訊。請注意查收！",
    next: "下一步",
    back: "上一步",
  },
  updateEmail: {
    title: "更新電子郵箱",
    member_profile: "會員檔案",
    updateEmail: "更新電子郵箱",
    new_email: "*新電子郵箱",
    sent: "發送",
    send_token: "發送驗證碼",
    email_errMsg1: "請輸入有效的電子郵件",
    email_errMsg2: "電子郵件已註冊",
    token: "*驗證碼",
    token_error: "驗證碼錯誤",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手機和電子郵箱都已使用",
    Msg2: "電子郵箱已使用",
  },
  updateMobile: {
    title: "修改手機號碼",
    member_profile: "會員檔案",
    updateMobile: "修改手機號碼",
    new_mobile: "*新手機號碼",
    sent: "發送",
    send_token: "發送驗證碼",
    mobile_errMsg1: "請輸入有效電話",
    mobile_errMsg2: "手機已註冊",
    token: "*驗證碼",
    token_error: "令牌錯誤",
    update: "更新",
    update_success: "更新成功！ ",
    done: "完成",
    Msg1: "手機和電子郵件都已使用",
    Msg2: "電子郵件已使用",
  },
  coupon: {
    title: "禮劵",
    member_profile: "會員檔案",
    coupon: "禮劵",
    birthdayNote: "您的生日電子禮券只會在您的生日月份內顯示。",
    active: "有效的",
    redeemed: "已兌換",
    expired: "過期",
    type_of_vouchers_opt1: "請選擇禮劵類型",
    type_of_vouchers_opt2: "類型A",
    type_of_vouchers_opt3: "類型B",
    type_of_vouchers_opt4: "類型C",
    type_of_vouchers_opt5: "類型D",
    expiry_date: "到期日期：",
    redeemed_store: "赎回商店：",
    vaild_date: "無效日期",
    description: "說明",
    vouchers_code: "禮劵代碼",
    done: "完成",
    no_coupon_msg: "沒有可用禮劵",
  },
  editProfile: {
    title: "編輯個人資料",
    member_profile: "會員檔案",
    editProfile: "編輯個人資料",
    general: "賬戶資料",
    security: "安全信息",
    subscriptions: "信息訂閱",
    salutation: "*稱謂",
    salutation_opt1: "請選擇您的稱謂",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "請選擇稱謂",
    gender: "*性別",
    gender_opt1: "請選擇您的性別",
    gender_opt2: "男性",
    gender_opt3: "女性",
    gender_errMsg: "請選擇性別",
    first_name: "*名字",
    first_name_errMsg: "請輸入名字",
    last_name: "*姓氏",
    last_name_errMsg: "請輸入姓氏",
    birth: "*出生日期",
    mobile: "*手機號碼",
    mobile_change: "修改",
    email: "*電子郵件",
    email_change: "修改",
    language: "*語言",
    language_opt1: "請選擇您的語言選項",
    language_opt2: "English",
    language_opt3: "簡體中文",
    update: "更新",
    previous_password: "*舊密碼",
    previous_password_errMsg: "舊密碼不正確",
    new_password: "*新密碼",
    new_password_errMsg1: "請輸入密碼",
    new_password_errMsg2: "密碼必須至少包含8個字母數字字符，並且至少包含一個大寫字母和一個特殊字符（?!@$%&*）",
    confirm_password: "*確認密碼",
    confirm_password_errMsg: "請確認密碼",
    subscriptions_txt1: "點擊下方按鈕，即表示您同意御寶、我司關聯方、相關實體以及代表我司行事的第三方（“業務合作夥伴”）可收集、使用及披露您在會員門戶網站上提供的個人信息。我司將向您提供由您同意接收的促銷、特別優惠，可能由我司的業務合作夥伴直接發送，我司可能會向我司的業務合作夥伴提供您的個人信息。",
    subscriptions_txt2: "我希望收到来自ITRG的营销/推广信息与材料。",
    subscriptions_txt3: "*请勾选提供的方框以表明您同意每一项。",
    subscriptions_txt4: "除非征得您的同意，否则御宝不会将您的个人资料用于上述用途。如果您改变主意，您随时可以在我们的会员门户网站 <link>https://members.imperialtreasure.com.hk<link> 上免费撤销您的同意。",
    by_email: "通過電郵",
    by_mobile: "通過短信",
    update_success: "更新成功！",
    done: "完成",
    password_update_success: "密碼修改成功！",
  },
  profile: {
    title: "會員",
    logout: "登出",
    click_to_renew: "單擊以更新​",
    activation_now: "立即激活",
    menber_id: "會員號碼:",
    member: "會員",
    active: "有效的",
    expired: "過期",
    salutation: "稱謂：",
    mobile: "手機號碼：",
    birth: "出生日期：",
    password: "密碼：",
    ellipsis: "········",
    change: "更改",
    gender: "性別：",
    email: "電子郵件：",
    cycle_start_date: "等級開始日期：",
    first_joined_date: "首次加入日期：",
    optin_email: "選擇電子郵件：",
    optin_sms: "選擇短信：",
    cycle_expiry_date: "週期到期日期：",
    language_preference: "語言選項：",
    spend: "HK$ 消費",
    expires_on: "  到期時間",
    spend_required: "還需淨消費",
    to_upgrade_to: "升級為",
    nett_spend_required: "維持至所需的淨支出",
    nett: "淨",
    to_maintain_to: "維持到",
    silver_member: "銀卡會員",
    gold_member: "金卡會員",
    diamond_member: "鑽石卡會員",
    total_nett_spend: "總淨消費金額",
    current_membership: "在當前會員年份*",
    i_dollar: "i-Dollar",
    last_update: "  最新一次更新：",
    i_dollar_earned: "赚取的 i-Dollar",
    i_dollar_converted: "已兑换的 i-Dollar",
    i_dollar_balance: "剩余的 i-Dollar",
    i_dollar_automatically: "$50 i-Dollars 將自動轉換為HK$250現金禮券",
    vouchers: "禮劵",
    currently_active: "當前有效",
    cash_vouchers: "現金禮劵",
    food_vouchers: "食品/促銷券",
    click_to_view: "單擊查看",
    transaction: "交易記錄",
    this_month: "當月記錄",
    last_3_months: "最近3個月",
    last_12_months: "過去12個月",
    transaction_details: "交易明細",
    transaction_date: "交易日期",
    transaction_time: "交易時間",
    outlet: "餐廳",
    receipt: "單據編碼",
    payment_method: "付款方式",
    receipt_details: "單據明細",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS 賺取:",
    total: "總額：",
    done: "完成",
    thankyou_msg: "謝謝你選擇我們的餐廳!",
    activate_membership: "激活會員資格",
    activate_membership_1: "只需 88 新元即可激活您的會員身份享受以下禮遇*：",
    activate_membership_2: "每消費 10 新元，即可賺取 i$1。累積 i$50 可兌換 50 新元電子現金禮券",
    activate_membership_3: "2 張 50 新元註冊電子禮券（有效期為 3 個月；不能同時使用）",
    activate_membership_4: "1 張 100 新元生日電子禮券（僅限生日當月，且單筆有效消費滿 280 新元時可使用）",
    activate_membership_5: "1 張免費和牛（1 份）電子禮券，可於任何御寶火鍋餐廳兌換",
    activate_membership_6: "1 張免費老虎石斑魚（1 份）電子禮券，可於任何御寶火鍋餐廳兌換",
    activate_membership_7: "率先收到最新信息和私人活動專屬邀請！",
    renew_membership: "會員更新",
    renew_membership_1: "您的會員資格已失效，現在只需S$38，即可更新會員資格，並在新一年的會員周期中收到以下電子禮劵：",
    renew_membership_2: "1 張 50 新元更新電子禮券（有效期為簽發之日起 3 個月）",
    renew_membership_3: "1 張 100 新元生日電子禮券（僅限生日當月，且滿最低消費 280 新元時可使用）",
    renew_membership_4: "3 張 30 新元的火鍋湯底電子禮券（有效期為簽發之日起 12 個月，每次來就餐僅限 (01) 次兌換，僅限堂食）",
    confirm_to_renew: "確認續訂",
    confirm_to_activate: "確認激活",
    change_password: "修改密碼",
    previous_password: "*舊密碼",
    previous_password_errMsg: "舊密碼不正確",
    new_password: "*新密碼",
    new_password_errMsg1: "請輸入並確認密碼",
    new_password_errMsg2: "密碼必須至少包含8個字母數字字符，並且至少包含一個大寫字母和一個特殊字符（?!@$%&*）​",
    confirm_password: "*確認密碼",
    close: "X 關閉",
    member_activated: "會員已激活",
    password_updated: "密碼修改成功！",
    friend_referral: "推薦朋友",
    view_more: "查看更多",
    add_friend: "添加",
    silver_tier: "銀卡",
    gold_tier: "金卡",
    diamond_tier: "鑽石卡",
    spend_date_format: "年過期",
  },
  friendReferral: {
    title: "推薦朋友",
    email: "電子郵箱",
    referee_email: "推薦朋友電子郵箱",
    emailAddress_errMsg: "請輸入有效的電子郵箱",
    mobile: "電話號碼",
    referee_mobile: "推薦朋友電話號碼",
    mobile_errMsg: "請輸入有效的電話號碼",
    mobile_errMsg2: "手機號碼已註冊",
    name: "姓名",
    referee_name: "推薦朋友姓名",
    name_errMsg: "請輸入推薦朋友姓名",
    referral_sucMsg: "你已成功推薦朋友!",
    referral_errMsg: "推薦朋友已為現有御寶註冊會員。",
    submit: "提交",
  },
  transactionDetails: {
    transaction_date: "交易日期",
    outlet: "餐廳",
    receipt_no: "單據編碼",
    amount_spend: "消費金額",
    amount_qualified_for_i_dollar: "符合 i-Dollar 金額",
    i_dollars: "i-Dollars",
    selected_start_date: "選定開始日期",
    selected_end_date: "選定結束日期",
    no_tranMsg: "所選期間無交易記錄。",
    action: "動作",
    apply: "應用",
    view: "查看",
  },
  contactUs: {
    title: "聯繫我們",
    title_message: "請填寫並在下面提交您的反饋，我們將在3-5個工作天內回覆您。",
    firstname: "名字",
    firstname_errMsg: "請輸入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "請輸入您的姓氏",
    emailAddress: "電子郵箱",
    emailAddress_errMsg: "請輸入有效的電子郵箱",
    mobile: "手機號碼",
    mobile_errMsg: "請輸入有效電話",
    type: "類型",
    type_opt1: "請輸入您的反饋信息類型 :",
    type_opt2: "聯絡我們",
    type_opt3: "查詢",
    type_opt4: "反饋",
    type_opt5: "電子報",
    type_errMsg: "請輸入您的反饋信息類型",
    message: "信息",
    message_errMsg: "請輸入您的反饋信息",
    captcha: "驗證碼",
    captcha_errMsg: "驗證碼不正確",
    feedback_sucMsg: "感謝您與我們聯繫並向我們提供寶貴的反饋。\n我們會盡快回复您。",
    submit: "提交",
  },
  languageSetting: {
    title: "語言設置",
    setting: "設置",
    language_preference: "語言選項",
    language_preference_opt1: "請選擇您的語言選項",
    language_preference_opt2: "English",
    language_preference_opt3: "繁體中文",
    language_preference_errMsg: "請選擇語言選項",
    submit: "提交",
  },
};