export default {
  login: {
    login: "LOGIN",
    email: "Email",
    mobile: "Mobile",
    mobile2: "MOBILE NO",
    password: "PASSWORD",
    errMsg1: "Please enter email & password",
    errMsg2: "Please enter mobile & password",
    errMsg3: "Your account isn't this region",
    errMsg4: "Incorrect login or password",
    errMsg5: "Incorrect login mobile or password",
    errMsg6: "Your registration is not complete until payment is received.",
    forgotpw: "Forgot password?",
    register: "Register",
    txt: "Not an Imperial Treasure Club member yet? Check out our ",
    txt_2: "Membership Privileges.",
  },
  header: {
    home: "HOME",
    eStore: "E-STORE",
    PRivileges: "PRivileges",
    register: "register",
    highlights: "highlights",
    faq: "faq",
    tnp: "terms & privacy",
    friendReferral: "Friend Referral",
    profile: "Profile",
  },
  footer: {
    aboutUs: "About Us",
    eStore: "E-STORE",
    faq: "FAQs",
    tnp: "Terms & Privacy",
    pr: "Participating Restaurant",
    profile: "Profile",
    contactUs: "Contact Us",
    copyright: "Copyright © 2023 Imperial Treasure Restaurant Group Pte Ltd.",
  },
  signUp: {
    signUp: "Membership Registration",
    title: "Register as a member of Imperial Treasure Club",
    mustFill: '"*" denotes required fields',
    step1: "Account Details",
    step2: "Security Information",
    step3: "Subscriptions",
    step4: "Reference",
    salutation: "Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    birth: "Birth Date",
    birth_errMsg: "Please select date of birth",
    birth_errMsg2: "You must 18 years old or older",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    emailAddress_errMsg2: "Email already registered",
    confirmEmailAddress: "Confirm Email Address",
    confirmEmailAddress_errMsg: "Please confirm email",
    gender: "Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "繁體中文",
    language_preference_errMsg: "Please select language preference",
    password_format: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    password: "*Password",
    password_errMsg: "Please enter password",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please Enter password again",
    step3_consent_opt: "By checking this box, I give my consent to Imperial Treasure to collect, use, store and/or disclose the below personal data provided by me in connection with the Imperial Treasure Club membership for Imperial Treasure to share my data with any of its associated or related companies or  third parties including restaurant franchisees and restaurant partners receiving Imperial Treasure’s management support, (collectively, “Related Parties”) for direct marketing purposes in order to receive news and information regarding the Related Parties’ products and services, including promotions, special offers and discounts offered by the Related Parties, so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from Imperial Treasure’s relationship with service providers within and outside of Hong Kong. I acknowledge that Imperial Treasure will share the economic benefit of such marketing activities.",
    step3_consent_txt: "I consent to the provision of my personal data below by Imperial Treasure to any Related Parties for the aforementioned purposes in accordance with its Privacy Notice*:–\n• name\n• mobile phone number\n• email address\n• date of birth",
    tnc_errMsg: "Please agree to Terms & Conditions to proceed",
    step3_title: "By checking the boxes below, I give my consent for Imperial Treasure Restaurant Group Pte Ltd ('ITRG') to collect, use, store and/or disclose any personal data provided by me and/or data related with my use of Imperial Treasure Club Membership (hereinafter the 'Data') for Imperial Treasure to share my data with any of its related companies or other third parties so that I may enjoy fully the benefits arising from my membership or loyalty programme, including benefits arising from Imperial Treasure's relationship with service providers outside of Singapore.",
    step3_q: "I would like to receive marketing and promotional messages and materials from the Related Parties through the following channels*:",
    step3_q_opt1: "By Email",
    step3_q_opt2: "By Mobile",
    step3_q1: "*Please tick the box(es) provided to indicate your consent for each item.",
    step3_q2: "Imperial Treasure will not provide your personal data to the Related Parties for the aforesaid purposes unless Imperial Treasure has obtained your written consent to such provision. If you change your mind, you can always, without charge, revoke your consent at our member portal at https://members.imperialtreasure.com.hk.",
    step4_q_err: "Please select where did you hear about our programme",
    step4_q: "Where did you hear about our programme?",
    step4_q_opt1: "Select a reference",
    step4_q_opt2: "Tsim Sha Tsui, Kowloon, Fine Chinese Cuisine",
    step4_q_opt3: "Crowne Plaza, Fine Chinese Cuisine",
    step4_q_opt16: "Word of Mouth",
    step4_q_opt17: "Social Media",
    step4_q_opt18: "Others",
    promo_code: "Promo Code",
    promo_code_input_msg: "Please enter a valid promo code (if any)",
    promo_code_err_msg: "Please enter valid promo code.",
    promo_code_re_msg: "Promo code is valid!",
    total: "Total:",
    refresh: "Refresh",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    submitForm: "Proceed to payment",
  },
  aboutUs: {
    aboutUs: "ABOUT US",
    txt1: "The Imperial Treasure Restaurant Group",
    txt2: "With our vast array of iconic Chinese delicacies and our commitment to impeccable service, Imperial Treasure has become synonymous with authentic fine Chinese cuisine. Since the establishment of our first restaurant in 2004, Imperial Treasure Restaurant Group has won the hearts of gourmands and casual diners alike.",
    txt3: "Today, the Group has more than 20 restaurants under its name, as well as over 50 accolades – including: 2-Michelin Stars in the Michelin Guide Shanghai (2017 – 2023) for Imperial Treasure Fine Chinese Cuisine (Yi Feng Galleria), 2-Michelin stars in the Michelin Guide Guangzhou  (2020 – 2022) and 1-Michelin star in the Michelin Guide Guangzhou  (2019) for Imperial Treasure Fine Chinese Cuisine (IGC), 1-Michelin star in the Michelin Guide Singapore (2017 – 2019,  2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (ION Orchard), 1-Michelin star in the Michelin Guide Hong Kong Macau (2018 – 2022) for Imperial Treasure Fine Chinese Cuisine (Tsim Sha Tsui), 1-Michelin star in the Michelin Guide Guangzhou (2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (Taikoo Hui), Michelin Plate by Michelin Guide Guangzhou (2018, 2020), Michelin Plate by Michelin Guide France (2020), Michelin Plate by Michelin Guide Singapore (2018 – 2019), Michelin Plate by Michelin Guide Great Britain & Ireland (2019), Two-Diamond in Meituan-Dianping’s Black Pearl Guide (2018), Top Chinese Restaurants of the World by Ch’ng Poh Tiong, Asia's 50 Best Restaurants (2013 – 2015).",
    txt4: "Imperial Treasure has restaurants in Singapore, Shanghai, Guangzhou, Hong Kong, Incheon, London and Paris.",
  },
  faqs: {
    faqs: "FAQs",
    general: "General",
    general_q1: "What is the Imperial Treasure Club?",
    general_q1_opt1: "Imperial Treasure Club is a cardless loyalty programme that allows members to enjoy exclusive privileges and rewards whenever they dine at any participating Imperial Treasure restaurants.",
    general_q2: "How do I become a member of Imperial Treasure Club? ",
    general_q2_opt1: "You may register online at https://members.imperialtreasure.com.hk.",
    general_q3: "Which are the participating restaurants in Hong Kong? ",
    general_q3_opt2: "Imperial Treasure Fine Chinese Cuisine",
    general_q3_opt2_1: "10/F One Peking, Tsim Sha Tsui, Kowloon | Tel: (852) 2613 9800",
    general_q3_opt2_2: "2/F Crowne Plaza, No. 8 Leighton Road, Causeway Bay | Tel:(852) 2663 9919",
    general_q4: "What is the validity of the membership? ",
    general_q4_opt1: "The membership is valid for 1 year from the date of sign up. E.g. If the registration is made on 1 July 2023, the membership will expire on 30 June 2024.",
    general_q5: "Where can I check the expiry date of my membership? ",
    general_q5_opt1: "You may log in to the member portal https://members.imperialtreasure.com.hk/ to check the expiry date of your membership.",
    general_q6: "What is the minimum age that I can apply as an Imperial Treasure Club member?",
    general_q6_opt1: "Customers need to be above 18 years of age to apply as an Imperial Treasure Club member.",
    general_q7: "Why should I register my details at Imperial Treasure Club? ",
    general_q7_opt1: "Registering your details at https://members.imperialtreasure.com.hk is to activate your account. In addition, you will also receive the latest news on upcoming members’ promotions, rewards, invitations to events and more!",
    general_q8: "Where do I update my personal particulars? ",
    general_q8_opt1: "To update or view your personal particulars, please log in to your account via the members’ portal at https://members.imperialtreasure.com.hk/",
    general_q9: "How do I check my e-vouchers and other rewards? ",
    general_q9_opt1: "You may log in to https://members.imperialtreasure.com.hk/ to check your membership privileges and rewards balance.",
    general_q10: "What if I forgotten my password? ",
    general_q10_opt1: "To reset your password, visit the members’ portal at https://members.imperialtreasure.com.hk/ and click on “Forgot Password”. Follow the on-screen instructions to reset your password.",
    general_q11: "What should I do if I have questions regarding my membership account?",
    general_q11_opt1: "If you have any questions regarding your membership account, please email us at hk.club@imperialtreasure.com",
    general_q12: "Is my Imperial Treasure Club membership transferrable? ",
    general_q12_opt1: "No. Membership is not transferrable.",
    general_q13: "How do I terminate my membership?",
    general_q13_opt1: "Member may choose to terminate their membership at any time before the expiry date by writing to hk.club@imperialtreasure.com. Kindly be advised that there will be no refunds made and/or early issuance of any unused e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all unused e-vouchers will be deemed forfeited.",
    membershipTypes: "Membership Types",
    membershipTypes_q1: "What are the types of membership?",
    membershipTypes_q1_opt1: "There are 3 membership types - Silver, Gold and Diamond.",
    membershipTypes_q2: "How to be a Silver / Gold / Diamond member? ",
    membershipTypes_q2_opt1: "To become a Silver member, it is by invitation and you can sign up via online.",
    membershipTypes_q2_opt2: "To become a Gold member, existing members need to accumulate qualifying spend of HKD 68,000* and above within the membership year to qualify.",
    membershipTypes_q2_opt3: "To become a Diamond member, existing members need to accumulate qualifying spend of HKD168,000* and above within the membership year to qualify.",
    membershipTypes_q2_opt4: "*Qualifying spend is calculated excluding 10% service charge.",
    membershipTypes_q3: "How to retain my membership status?",
    membershipTypes_q3_opt1: "To maintain as a Gold member, simply accumulate qualifying spend of HKD 68,000* within the membership year to renew your membership status.",
    membershipTypes_q3_opt2: "To maintain as a Diamond member, simply accumulate qualifying spend of HKD 168,000* within the membership year to renew your membership status.",
    membershipTypes_q3_opt3: "*Qualifying spend is calculated excluding 10% service charge.",
    membership: "Membership Privileges & Rewards",
    membership_q1: "What are the privileges for different tiers? ",
    complimentary_treats: "Complimentary Treats",
    sliver: "Sliver",
    gold: "Gold",
    diamond: "Diamond",
    gold_note: "(Spend HKD68,000 and above within membership year)",
    diamond_note: "(Spend HKD168,000 and above within membership year)",
    type_of_privileges_1_1: "1X Pigeon (3 pieces/ set)",
    type_of_privileges_1_2: "-",
    type_of_privileges_1_3: "Yes",
    type_of_privileges_1_4: "Yes",
    type_of_privileges_2_1: "1X Roasted Duck (Whole)",
    type_of_privileges_2_2: "-",
    type_of_privileges_2_3: "Yes",
    type_of_privileges_2_4: "Yes",
    type_of_privileges_3_1: "1X Suckling Pig (Whole)",
    type_of_privileges_3_2: "-",
    type_of_privileges_3_3: "-",
    type_of_privileges_3_4: "Yes",
    type_of_privileges_4_1: "1X Red Wine (bottle)",
    type_of_privileges_4_2: "-",
    type_of_privileges_4_3: "Yes",
    type_of_privileges_4_4: "Yes",
    type_of_privileges_5_1: "1x Champagne (bottle)",
    type_of_privileges_5_2: "-",
    type_of_privileges_5_3: "-",
    type_of_privileges_5_4: "Yes",
    type_of_privileges_6_1: "1X HKD1000 Cash e-voucher",
    type_of_privileges_6_2: "-",
    type_of_privileges_6_3: "Yes",
    type_of_privileges_6_4: "Yes",
    type_of_privileges_7_1: "1X HKD2000 Cash e-voucher",
    type_of_privileges_7_2: "-",
    type_of_privileges_7_3: "-",
    type_of_privileges_7_4: "Yes",
    membership_q2: "How do I know the expiry of my privileges? ",
    membership_q2_opt1: "To check the expiry dates of your privileges, simply log in to the member’s portal https://members.imperialtreasure.com.hk and view the details reflected under the ‘Vouchers’ section.",
    membership_q3: "How do I identify myself as an Imperial Treasure Club member？ ",
    membership_q3_opt1: "To identify yourself as a member, simply provide your mobile number to our service staff.",
    membership_q4: "How do I enjoy my complimentary treats? ",
    membership_q4_opt1: "For redemption of Roasted Duck, Suckling Pig and Pigeon, one (01) day advance order is required and available at Imperial Treasure Fine Chinese Cuisine. Member must be physically present (proof of identification required) to redeem any membership privileges.",
    membership_q5: "Can I as a corporate customer enjoy corporate discount with the Imperial Treasure Club membership?",
    membership_q5_opt1: "No, corporate discount is not eligible for use together with the Imperial Treasure Club membership. Corporate customers can only choose either corporate discount or the Imperial Treasure Club membership for each receipt.",
    membership_q6: "Can I enjoy my Imperial Treasure Club membership privileges in another country where there is an Imperial Treasure restaurant? ",
    membership_q6_opt1: "The Imperial Treasure Club membership privileges are currently available at participating restaurants in its own country only. For overseas privileges, please follow us to receive the updates.",
    membership_q7: "Is my Imperial Treasure Club membership transferrable?",
    membership_q7_opt1: "No. Membership is not transferrable.",
    earning: "Earning & Redemption of points",
    earning_q1: "How do I earn i$?",
    earning_q1_opt1: "Earn i$1 for every HKD10* spent at participating restaurants (*excludes service charge, prevailing government taxes, e-vouchers and purchase or use of Imperial Treasure dining vouchers and festive items). Member must be physically present (proof of identification required) at the point of dining. Requests on i$ accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, e-vouchers and i$ earned. Combining multiple membership accounts or splitting the bill to earn i$ is strictly not allowed. Balance i$ accrued shall expire upon expiry of membership. Balance i$ accrued is however granted a grace period of three (03) months upon expiry membership and must be converted to a HKD250 e-voucher within the grace period, otherwise deemed forfeited.",
    earning_q2: "How do I redeem my i$?",
    earning_q2_opt1: "For every i$250 earned, members will automatically receive* a HKD250Cash e-voucher valid for use within the membership year. The HKD250 e-voucher can be used to offset the bill when dining at any participating restaurants. Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Combining multiple membership accounts or splitting the bill to redeem membership privileges is not allowed.\n(*system automatically converts i$ at a stipulated time daily)",
    earning_q3: "Will my i$ expire?",
    earning_q3_opt1: "Yes. i$ earned is only valid during the membership period and will expire once the membership expires. Balance i$ will be brought forward for a grace period of three (03) months upon expiry of membership and must be converted to a HKD250 Cash e-voucher within the grace period, otherwise deemed forfeited.",
    earning_q4: "Will my i$ be reflected immediately once I make a purchase?",
    earning_q4_opt1: "Yes. The system will reflect i$ earned instantly but do allow for time lapses that may be caused by any delays in the network during unforeseen circumstances.",
    earning_q5: "Can I use multiple membership accounts to earn i$ and redeem membership privileges?",
    earning_q5_opt1: "No. Combining multiple membership accounts to earn i$ or redeem membership privileges is not allowed.",
    earning_q6: "Can I split my bill to earn i$ and/or redeem membership privileges?",
    earning_q6_opt1: "No. Splitting of bill to earn i$ and/or redeem membership privileges is not allowed.",
    earning_q7: "Can my family and friends earn i$ or redeem membership privileges if I am not present?",
    earning_q7_opt1: "No. Members must be present to enjoy the membership privileges.",
    earning_q8: "Can I earn i$ or redeem membership privileges for orders made via third party online delivery platforms?",
    earning_q8_opt1: "No. Earning of i$ and redemption of membership privileges are not eligible for orders made via third party online delivery platforms.",
    renewal: "Renewal",
    renewal_q1: "How do I renew my membership?",
    renewal_q1_opt1: "Accumulate a total spend of at least HKD68,000* and above (Gold) or HKD168,000* and above (Diamond) within the current membership year to qualify for an automatic renewal upon the expiry of the current membership cycle.\n*Qualifying spend is calculated before service charge.",
  },
  tnc: {
    title: "Terms And Privacy",
    consent_1: "Consent wording on sign-up page",
    consent_1_1: "□ I acknowledge that I have read and agree to the Terms and Conditions",
    consent_1_2: "[link:https://members.imperialtreasure.com.hk/cn/tnc] set forth herein.",
    consent_2: "Opt-in Wording on sign-up page",
    consent_2_1: "□ By checking this box,  I give my consent to Imperial Treasure (International) Company Ltd (“Imperial Treasure”) to collect, use, store and/or disclose the below personal data provided by me in connection with the Imperial Treasure Club membership for direct marketing purposes in order to receive news and information regarding Imperial Treasure’s products and services, including promotions, special offers, discounts and membership offered by Imperial Treasure in accordance with its Privacy Notice. I consent to the use of my personal data below for marketing Imperial Treasure’s products/services in accordance with its Privacy Notice*:–",
    consent_2_2: "□ name",
    consent_2_3: "□ residential address",
    consent_2_4: "□ mobile phone number",
    consent_2_5: "□ email address",
    consent_2_6: "□ date of birth ",
    consent_2_7: "I would like to receive marketing and promotional messages and materials from Imperial Treasure through the following channels*:",
    consent_2_8: "□ By Email    □ By Post     □ By SMS",
    consent_2_9: "*Please tick the box(es) provided to indicate your consent for each item.",
    consent_2_10: "Imperial Treasure will not use your personal data for the aforesaid purposes unless Imperial Treasure has obtained your consent. If you change your mind, you can always, without charge, revoke your consent at our member portal at https://members.imperialtreasure.com.hk.",
    consent_3_1: "□ By checking this box, I give my consent to Imperial Treasure to collect, use, store and/or disclose the below personal data provided by me in connection with the Imperial Treasure Club membership for Imperial Treasure to share my data with any of its associated or related companies or  third parties including restaurant franchisees and restaurant partners receiving Imperial Treasure’s management support, (collectively, “Related Parties”) for direct marketing purposes in order to receive news and information regarding the Related Parties’ products and services, including promotions, special offers and discounts offered by the Related Parties, so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from Imperial Treasure’s relationship with service providers within and outside of Hong Kong. I acknowledge that Imperial Treasure will share the economic benefit of such marketing activities.",
    consent_3_2: "I consent to the provision of my personal data below by Imperial Treasure to any Related Parties for the aforementioned purposes in accordance with its Privacy Notice*:–",
    consent_3_3: "□ name",
    consent_3_4: "□ residential address",
    consent_3_5: "□ email address",
    consent_3_6: "I would like to receive marketing and promotional messages and materials from the Related Parties through the following channels*:",
    consent_3_7: "□ By Email    □ By Post",
    consent_3_8: "*Please tick the box(es) provided to indicate your consent for each item.",
    consent_3_9: "Imperial Treasure will not provide your personal data to the Related Parties for the aforesaid purposes unless Imperial Treasure has obtained your written consent to such provision. If you change your mind, you can always, without scharge, revoke your consent at our member portal at https://members.imperialtreasure.com.hk.",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure (International) Company Ltd ("Imperial Treasure") would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts. We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time. To become a Member, you must be 18 years of age or above.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: 'If you become a Member, you will need to provide Imperial Treasure with certain personal data (including your name, date of birth and contact details such as email address and mobile number). Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice stated on https://members.imperialtreasure.com.hk (the "Member\'s Portal").',
    membership_1_3: "1.3 Membership Tiers",
    membership_1_3_1: '1.3.1 Membership registration, i-dollars, and Cash e-Vouchers',
    membership_1_3_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_3_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_3_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, tax, Cash e-Vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-Vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_3_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [link:https://members.imperialtreasure.com.hk/cn/tnc]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_2: "1.3.2 Membership Tiers",
    membership_1_3_2_1: "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure in its sole discretion on the membership website [link: members.imperialtreasure.com.hk] for the country in which you signed up.",
    membership_1_3_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon the expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_3_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [link: members.imperialtreasure.com.hk] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon the expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_3_2_4: "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier programme rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable notice by email. Please check the membership programme website regularly for updates.",
    membership_1_3_3: "1.3.3 Membership Tier Rewards",
    membership_1_3_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [link: members.imperialtreasure.com.hk]. The validity and terms and conditions of such Rewards are as set out in [link:https://members.imperialtreasure.com.hk/cn/tnc], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability.  Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_4: "1.3.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_3_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made.",
    membership_1_3_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_4: "1.4 Amending personal data and terminating membership",
    membership_1_4_1: "You may amend your personal data or marketing preferences at any time via our Member's Portal. You will need to inform us in writing at hk.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-Vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and unused Cash e-Vouchers will be deemed forfeited.",
    membership_1_5: "1.5 Termination and Cancellation",
    membership_1_5_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”), are governed by and construed in accordance with the laws of Hong Kong. Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of Hong Kong courts.",
    membership_3: "3. Consent",
    membership_3_1: "I would like to join the Imperial Treasure Membership club and I consent you to holding my personal data in accordance with your Privacy Policy.",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure (International) Company Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protecting the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_2: 'This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: "7. What rights do I have".',
    privacy_1_3: 'Maintaining the privacy of our customer\'s information is important to us. We do not disclose rent or sell our customer\'s personal data to third parties (except as described in section "4. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, date of birth and phone number; and",
    privacy_2_1_3: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes:",
    privacy_2_2_1: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_1_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_1_2: "to facilitate your sign up for our membership programme;",
    privacy_2_2_1_3: "to use information you provide to provide products and services you have requested; and",
    privacy_2_2_1_4: "to respond to comments, queries and feedback.",
    privacy_2_2_2: "Where you give us consent:",
    privacy_2_2_2_1: "we provide you with marketing and promotional materials and other information about our products (including promotions and special offers), as well as other products and services provided by our Affiliates and partners (as detailed in section 3 below); and",
    privacy_2_2_2_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_3: "For purposes which are required by law: in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3_1: "The provision of your name, family name, contact number, date of birth, and email address is mandatory such that we can contact you for sharing updates on membership status, points, vouchers and promotions, register you as a user of the Website and sign you up for our membership programme. If the relevant data is not provided, then we will not be able to contact you for sharing updates on membership status, points, vouchers and promotions, register you as a user of the Website and sign you up for our membership programme. All other provision of your information is optional.",
    privacy_2_3_2: "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_4: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_4_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_4_2: "to improve the contents of the Website;",
    privacy_2_4_3: "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you;",
    privacy_2_4_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_4_5: "to obtain statistic data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_5: "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. DIRECT MARKETING",
    privacy_3_1: "Where we have your consent, we may use the personal data you provided at the Member’s Portal for the application as a Member (including your name and contact details such as email address and phone number) for providing marketing materials that you have agreed to receive, including news and information regarding our products and services, including promotions, special offers, discounts and membership offered by us, and we may also share your personal data with our Affiliates and third parties including restaurant franchisees and restaurant partners receiving Imperial Treasure’s management support (together with our Affiliates, “Related Parties”),  for the sending of marketing materials that you have agreed to receive, including news and information regarding the Related Parties’ products and services, including promotions, special offers and discounts offered by the Related Parties in accordance with the Personal Data (Privacy) Ordinance (Cap. 486) (“PDPO”) and this Privacy Notice. Please note that marketing materials may be sent directly by our Related Parties, and we may disclose your personal data to them for that purpose. For the avoidance of doubt, we and our Related Parties may not use personal data provided by you for direct marketing unless we received your consent and/or indication of no objection for such use and such provision. You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, free of charge, at any time. If you do not wish to receive any marketing information from us and/or if you do not wish us to provide your personal data to any third parties for direct marketing purposes, you can opt-out by following the instructions in the communication where this is an electronic message, or by contacting us using the details set out in section: “10. Contact Us” below.",
    privacy_4: "4. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_4_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_4_1: "In order to provide services to you, we may disclose and/or transfer your personal data out of Hong Kong, as described in this Privacy Notice:",
    privacy_4_1_1_1: "We may share your personal data with our Related Parties and loyalty programme vendors for the purposes of: Fulfilling a contract with you, providing customer services and membership services and benefits, facilitating cross-country access of your personal data to allow you to use member privileges or other membership services, facilitating your registration as a user of the Website, and/or marketing of products and services to you as described in section 3 above.",
    privacy_4_1_1_2: "For the purpose of this Privacy Notice, “Affiliate” means an entity that owns or controls, is owned or controlled by or is or under common control or ownership with Imperial Treasure, where control is defined as the possession, direct or indirect, of the power to direct or cause the direction of the management and policies of an entity, whether through ownership of voting securities, by contract or otherwise.",
    privacy_4_1_2: "We may permit certain trusted third parties, for example, Google Analytics, to access your personal data in connection with their performance of services to maintain and operate the Website and certain features on the Website. For example, we may use third parties to host the Website; operate various features available on the Website; send emails; analyze data; provide search results and links and assist in fulfilling your orders and may share personal data or other information with our parent, subsidiaries, divisions, and Affiliates.",
    privacy_4_1_3: "We may employ a third-party service to collect the above-mentioned information, including personal data, and use their tracking services on our behalf. However, this information is strictly confidential and will not be shared with others.",
    privacy_4_1_4: "We may disclose personal data as an asset in connection with a proposed or actual merger or sale (including any disclosures made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.",
    privacy_4_1_5: "We reserve the right to disclose information, including personal data, in order to comply with a subpoena, court order, administrative or governmental order, or any other requirement of law, or when we, in our sole discretion, believe it is necessary in order to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk reduction. We may also disclose or transfer personal data as otherwise permitted or required by law.",
    privacy_4_1_6: "We may disclose aggregated and anonymized data to third parties.",
    privacy_4_2: "Your personal data may be transferred out of Hong Kong from time to time. Such transfers will be carried out in accordance with the PDPO. Third parties, with the exception of Hong Kong public agencies, are subject to data protection obligations and may only use your personal data to perform the necessary functions.",
    privacy_5: "5. HOW WE PROTECT YOUR INFORMATION",
    privacy_5_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure, and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_6: "6. LINKS TO THIRD PARTY SITES",
    privacy_6_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_6_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_7: "7. WHAT RIGHTS DO I HAVE",
    privacy_7_1: "You have the right to ask us for a copy of your personal data and to correct any inaccurate data about you.",
    privacy_7_2: "In addition, wherever we rely on your consent, you will always be able to withdraw that consent.",
    privacy_7_3: "These rights may be limited, for example if fulfilling your request would reveal personal data about another person, where they would infringe the rights of a third party (including our rights). Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_7_4: "If you are based in Hong Kong and have unresolved concerns, you have the right to complain to the Privacy Commissioner of Personal Data.",
    privacy_7_5: 'To exercise any of these rights, you can get in touch with us - by contacting our Data Protection Manager (See section "10. Contact us" below). To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_7_6: 'Please allow forty (40) working days for your request to be processed and in any event, we will process your requests as soon as possible in accordance with legal requirements.',
    privacy_8: "8. RETENTION OF INFORMATION",
    privacy_8_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_8_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_8_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_8_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_9: "9. PRIVACY POLICY CHANGES",
    privacy_9_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email or obtain your consent as may be required by applicable law. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_10: "10. CONTACT US",
    privacy_10_1: "If you have any questions about this Privacy Notice, or to contact our Data Protection Manager, please contact us at hk.club@imperialtreasure.com.",
  },
  outlets: {
    outlets: "Participating Restaurants",
    restaurant_name: "Restaurant Name",
    address: "Address",
    contact: "Contact",
    email: "Email",
    outlets_1_1: "Imperial Treasure Fine Chinese Cuisine",
    outlets_1_2: "10/F, One Peking,\n1 Peking Road, Tsim Sha Tsui, Kowloon, Hong Kong",
    outlets_1_3: "(852) 2613 9800",
    outlets_1_4: "finechinese_onepeking@imperialtreasure.com",
    outlets_2_1: "Imperial Treasure Fine Chinese Cuisine",
    outlets_2_2: "2/F, Crowne Plaza, \n8 Leighton Road, Causeway Bay, Hong Kong",
    outlets_2_3: "(852) 2663 9919",
    outlets_2_4: "finechinese_crowneplaza@imperialtreasure.com",
  },
  privilege: {
    title: "PRIVILEGES",
    privilege: "MEMBERSHIP PRIVILEGES",
    privelege_txt: "Earn i$1 with every HKD10 # spent at participating restaurants.\nAccumulate i$250 to receive a HKD250 cash e-voucher for use at participating restaurants.",
    silver: "SILVER",
    silver_1: "By invitation only",
    gold: "GOLD",
    gold_1: "Upgrade to GOLD tier when you spend HKD68,000 # and above within the membership year.",
    gold_2: "Waiver of annual renewal fee",
    gold_3: "Complimentary treats: \n1 X Roasted Duck \n1 X Pigeon (3 pieces/set) \n1 X Red Wine (bottle) \n1 X HKD1000 Cash e-voucher",
    diamond: "DIAMOND",
    diamond_1: "Upgrade to DIAMOND tier when you spend HKD168,000 # and above within the membership year.",
    diamond_2: "All of GOLD benefits plus:",
    diamond_3: "Waiver of annual renewal fee",
    diamond_4: "Complimentary treats: \n1 X Suckling Pig \n1 X Champagne (bottle) \n1 X HKD2000 Cash e-voucher",
    service_charge_note: "* Does not include service charge (if any), other terms and conditions apply.",
    sign_up_now: "Sign up now",
  },
  resetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    new_password: "*New password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm new password",
    confirm_password_errMsg: "Please confirm password",
    reset_password2: "Reset password",
    has_been_reset: "Password has been reset!",
    login: "Login",
  },
  forgetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    forgot_password: "Forgot password?",
    reset_pw_method: "Please select password reset method",
    by_mobile: "By mobile",
    by_email: "By email",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    reset_password2: "Reset password",
    reset_password_sent_email: "Reset password link has been sent to your email.",
    reset_password_sent_mobile: "Reset password link has been sent to your mobile.",
    next: "Next",
    back: "Back",
  },
  updateEmail: {
    title: "Update Email",
    member_profile: "Member Profile",
    updateEmail: "Update Email",
    new_email: "*New Email Address",
    sent: "Sent",
    send_token: "Send Token",
    email_errMsg1: "Please enter valid email",
    email_errMsg2: "Email already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  updateMobile: {
    title: "Update Mobile",
    member_profile: "Member Profile",
    updateMobile: "Update Mobile",
    new_mobile: "*New Mobile No.",
    sent: "Sent",
    send_token: "Send Token",
    mobile_errMsg1: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  coupon: {
    title: "Vouchers",
    member_profile: "Member Profile",
    coupon: "Vouchers",
    birthdayNote: "Your birthday e-voucher will only be reflected during your birthday month.",
    active: "Active",
    redeemed: "Redeemed",
    expired: "Expired",
    type_of_vouchers_opt1: "Please select type of vouchers",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "Expiry Date:",
    redeemed_store: "Redeemed Outlet:",
    vaild_date: "Valid Date",
    description: "Description",
    vouchers_code: "Voucher Code",
    done: "Done",
    no_coupon_msg: "No coupons available",
  },
  editProfile: {
    title: "Edit Profile",
    member_profile: "Member Profile",
    editProfile: "Edit Profile",
    general: "General Information",
    security: "Security",
    subscriptions: "Subscriptions",
    salutation: "*Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    gender: "*Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    first_name: "*Given Name",
    first_name_errMsg: "Please enter given name",
    last_name: "*Family Name",
    last_name_errMsg: "Please enter family name",
    birth: "*Date of Birth",
    mobile: "*Mobile No.",
    mobile_change: "Change",
    email: "*Email",
    email_change: "Change",
    language: "*Language",
    language_opt1: "Please select your language preference",
    language_opt2: "English",
    language_opt3: "简体中文",
    update: "Update",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please confirm password",
    subscriptions_txt1: "By checking the box(es) below, I give my consent to Imperial Treasure Restaurant Group Pte Ltd ('ITRG') to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club Membership ('Data') for ITRG to share my data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of Singapore.",
    subscriptions_txt2: "I would like to receive marketing and promotional messages and materials from the Related Parties through the following channels*:",
    subscriptions_txt3: "*Please tick the box(es) provided to indicate your consent for each item.",
    subscriptions_txt4: "Imperial Treasure will not provide your personal data to the Related Parties for the aforesaid purposes unless Imperial Treasure has obtained your written consent to such provision. If you change your mind, you can always, without charge, revoke your consent at our member portal at <link>https://members.imperialtreasure.com.hk<link>.",
    by_email: "By Email",
    by_mobile: "By Mobile",
    update_success: "Update Success!",
    done: "Done",
    password_update_success: "Password Update Success!",
  },
  profile: {
    title: "Member",
    logout: "Logout",
    click_to_renew: "CLICK TO RENEW​",
    activation_now: "ACTIVATE NOW",
    menber_id: "Member ID:",
    member: "member",
    active: "Active",
    expired: "Expired",
    salutation: "Salutation:",
    mobile: "Mobile No.:",
    birth: "Date of Birth:",
    password: "Password:",
    ellipsis: "········",
    change: "Change",
    gender: "Gender:",
    email: "Email:",
    cycle_start_date: "Tier Start Date:",
    first_joined_date: "First Joined Date:",
    optin_email: "Opt-in Email:",
    optin_sms: "Opt-in SMS:",
    cycle_expiry_date: "Cycle Expiry Date:",
    language_preference: "Language Preference:",
    spend: "HK$ Spend",
    expires_on: "  Expires on",
    spend_required: "Nett Spend required",
    to_upgrade_to: "to upgrade to",
    nett_spend_required: "Nett Spend required to maintain ",
    nett: "Nett",
    to_maintain_to: "to maintain ",
    silver_member: "Silver",
    gold_member: " Gold",
    diamond_member: " Diamond",
    total_nett_spend: "Total Nett Spend",
    current_membership: "in current membership year*",
    i_dollar: "i-Dollar",
    last_update: "  Last Update : ",
    i_dollar_earned: "i-Dollar Earned",
    i_dollar_converted: "i-Dollar Converted",
    i_dollar_balance: "i-Dollar Balance",
    i_dollar_automatically: "$250 i-Dollars are automatically converted to HK$250 Cash e-voucher",
    vouchers: "Vouchers",
    currently_active: "Currently Active",
    cash_vouchers: "Cash Vouchers",
    food_vouchers: "Food/Promotion Vouchers",
    click_to_view: "CLICK TO VIEW",
    transaction: "Transactions",
    this_month: "This Month",
    last_3_months: "Last 3 Months",
    last_12_months: "Last 12 Months",
    transaction_details: "Transaction Details",
    transaction_date: "Transaction Date",
    transaction_time: "Transaction Time",
    outlet: "Outlet",
    receipt: "Receipt No.",
    payment_method: "Payment Method",
    receipt_details: "Receipt Details",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS EARNED:",
    total: "Total :",
    done: "Done",
    thankyou_msg: "Thank you for dining with us!",
    activate_membership: "Activate membership",
    activate_membership_1: "Activate your membership with just S$88 to enjoy the following privileges:",
    activate_membership_2: "Earn i$1 with every $10 spent. Accumulate i$50 to receive a $50 cash e-voucher",
    activate_membership_3: "2 X S$50 Sign Up e-voucher (valid for 3 months; cannot be combined for use)",
    activate_membership_4: "1 X S$100 Birthday e-voucher, minimum spend of S$280 applies, valid only during birthday month",
    activate_membership_5: "1 X Complimentary Wagyu Beef (1 portion) e-voucher, redeemable at any Imperial Treasure Steamboat Restaurant",
    activate_membership_6: "1 X Complimentary Tiger Garoupa (1 portion) e-voucher, redeemable at any Imperial Treasure Steamboat Restaurant",
    activate_membership_7: "Be the first to receive latest updates and exclusive invitations to private events! ",
    renew_membership: "Renew Membership",
    renew_membership_1: "Click to Renew button for EXPIRED members: \n Your membership has expired. You can renew it now for $38 and receive the following e-vouchers during your new membership cycle.",
    renew_membership_2: "1 X S$50 Renewal e-voucher, valid for 3 months from date of issue",
    renew_membership_3: "1 X S$100 Birthday e-voucher, min. spend of S$280 applies, valid during birthday month",
    renew_membership_4: "3 X S$30 Steamboat Soup Base e-vouchers (valid for 12 months from date of issue, limited to (01) redemption per visit, for dine-in only)",
    confirm_to_renew: "Confirm To Renew",
    confirm_to_activate: "Confirm To Activate",
    change_password: "Change Password",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter & confirm password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)​",
    confirm_password: "*Confirm Password",
    close: "X CLOSE",
    member_activated: "Member activated",
    password_updated: "Password updated",
    friend_referral: "Friend Referral",
    view_more: "VIEW MORE",
    add_friend: "ADD",
    silver_tier: "Silver ",
    gold_tier: "Gold ",
    diamond_tier: "Diamond ",
    spend_date_format: "Expired year",
  },
  friendReferral: {
    title: "Friend Referral",
    email: "EMAIL",
    referee_email: "Referee's Email",
    emailAddress_errMsg: "Please enter valid email.",
    mobile: "MOBILE NUMBER",
    referee_mobile: "Referee’s Mobile",
    mobile_errMsg: "Please enter valid mobile",
    mobile_errMsg2: "Phone already registered",
    name: "NAME",
    referee_name: "Referee's Name",
    name_errMsg: "Please enter referee's name.",
    referral_sucMsg: "You have successfully referred your friend!",
    referral_errMsg: "Referee is an existing registered Imperial Treasure Club member.",
    submit: "Submit",
  },
  transactionDetails: {
    transaction_date: "Transaction Date",
    outlet: "Outlet",
    receipt_no: "Check/Receipt No",
    amount_spend: "Total Paid",
    amount_qualified_for_i_dollar: "Amount Qualified For i-Dollar",
    i_dollars: "i-Dollars",
    selected_start_date: "Selected start date",
    selected_end_date: "Selected end date",
    no_tranMsg: "No transaction records during the selected period.",
    action: "Action",
    apply: "Apply",
    view: "View",
  },
  contactUs: {
    title: "Contact Us",
    title_message: "Please complete and submit your feedback below and we will get back to you within 3-5 working days.",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    type: "Type",
    type_opt1: "Please enter your feedback message type :",
    type_opt2: "Options",
    type_opt3: "Enquiry",
    type_opt4: "Feedback",
    type_opt5: "Newsletter Subscription",
    type_errMsg: "Please enter your feedback message type",
    message: "Message",
    message_errMsg: "Please enter your feedback message",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    feedback_sucMsg: "Thank you for reaching out and providing us with valuable feedback. \nWe will respond to you very soon.",
    submit: "Submit",
  },
  languageSetting: {
    title: "Language Setting",
    setting: "Setting",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "繁體中文",
    language_preference_errMsg: "Please select language preference",
    submit: "Submit",
  },
};