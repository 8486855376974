import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  ImageBackground,
  SafeAreaView,
  TextInput,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import Header from "../components/header";
import Footer from "../components/footer";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "../components/AsyncStorage";
import { API_MIDDLEWARE, API_REGION } from "@env";
import IntlPhoneInput from "../components/IntlPhoneInput";
import i18n from "../components/i18n";
import axios from "axios";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";
import Captcha from "../components/captcha";

let disableBtn = false;

const Login = () => {
  const navigation = useNavigation();

  const [user, setUser] = useState("");
  const [password, setPasword] = useState("");
  const [eyeIcon, setEyeIcon] = useState("eye");
  const [securePassword, setSecurePassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loginMethod, setLoginMethod] = useState("mobile");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");

  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [recaptchaErr, setRecaptchaErr] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.removeValue("mCode");

    return () => {
      mounted.current = false;
    };
  }, []);

  const goSignUp = () => {
    if (!disableBtn) {
      //disableBtn = true;
      navigation.push("SignUp");
    }
  };

  const resetUser = () => {
    setUser("");
  };

  const showHidePassword = () => {
    if (eyeIcon == "eye") {
      setEyeIcon("eye-slash");
      setSecurePassword(false);
    } else {
      setEyeIcon("eye");
      setSecurePassword(true);
    }
  };

  const signIn = () => {
    // Check Recaptcha Status
    if (!recaptchaValid) {
      setRecaptchaErr(true);
      return;
    } else setRecaptchaErr(false);

    let err = false;
    setFormErr(false);
    setIsLoading(true);

    if (loginMethod == "mobile") {
      if (phone == "" || password == "") {
        err = true;
        setFormErr(true);
        setErrMsg(i18n.t("login.errMsg2"));
        setIsLoading(false);
      }
    }

    if (loginMethod == "email") {
      if (user == "" || password == "") {
        err = true;
        setFormErr(true);
        setErrMsg(i18n.t("login.errMsg1"));
        setIsLoading(false);
      }
    }

    if (!err) {
      let loginUser = "";
      let formData = new FormData();
      formData.append("method", "login");
      formData.append("password", password);

      if (validEmail(user)) {
        formData.append("email", user);
        loginUser = user;
      } else {
        formData.append("mobile", dialCode + phone.replace(/\s/g, ""));
        loginUser = dialCode + phone.replace(/\s/g, "");
      }

      axios
        .post(API_MIDDLEWARE, formData)
        .then(function (response) {
          console.log(response.data);
          console.log("Tier checking before login:", response.data.tier);

          if (response.data.status == "error") {
            setFormErr(true);
            // setErrMsg(capitalize(response.data.message));
            setErrMsg(i18n.t("login.errMsg4"));
          } else {
            if (response.data.tier == "" || response.data.tier == null) {
              alert(i18n.t("login.errMsg6"));
            } else if (API_REGION == response.data.region) {
              let store = {
                code: response.data.code,
                username: response.data.customer_username,
                region: response.data.region,
              };

              AsyncStorage.storeData("mCode", JSON.stringify(store));
              setUser("");
              setPasword("");
              setIsLoading(false);
              navigation.push("Profile");
              return false;
            } else {
              setFormErr(true);
              setErrMsg(i18n.t("login.errMsg3"));
              setIsLoading(false);
              return false;
            }
          }

          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("Error :", error);
          setIsLoading(false);
        });
    }
  };

  const validEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const onChangeMobile = ({ dialCode, unmaskedPhoneNumber, phoneNumber, isVerified }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setPhone(unmaskedPhoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const capitalize = (string) => {
    if (string) {
      return string.replace(/^./, (str) => str.toUpperCase());
    } else {
      return "";
    }
  };

  // const [countries, setCountries] = useState({
  //   "Hong Kong": { code: "hk" },
  //   "Hong Kong": { code: "hk" },
  //   "Hong Kong": { code: "hk" },
  // });

  // const renderCustomModal = (modalVisible, countries, onCountryChange) => (
  //   <Modal visible={modalVisible}>
  //     <SafeAreaView style={{ flex: 1 }}>
  //       <View>
  //         <View>
  //           <TextInput placeholder="Search" />
  //           <Text>🔍</Text>
  //         </View>
  //         <FlatList
  //           style={{ flex: 1 }}
  //           data={countries}
  //           keyExtractor={(item, index) => index.toString()}
  //           renderItem={({ item }) => (
  //             <TouchableWithoutFeedback
  //               onPress={() => onCountryChange(item.code)}
  //             >
  //               <Text>{item["your language code here for example tr"]}</Text>
  //             </TouchableWithoutFeedback>
  //           )}
  //         />
  //       </View>
  //       <TouchableOpacity onPress={() => this.phoneInput.hideModal()}>
  //         <Text>CLOSE</Text>
  //       </TouchableOpacity>
  //     </SafeAreaView>
  //   </Modal>
  // );

  return (
    <View className="flex-1 md:bg-[#efefef] w-full" pointerEvents={!isLoading ? "auto" : "none"}>
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 items-center content-start w-full min-h-full"
      >
        {isLoading && (
          <View
            className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
            style={{ position: "fixed" }}
            pointerEvents={"none"}
          >
            <ActivityIndicator size="large" color={"#BD9B60"} />
          </View>
        )}
        <SafeAreaView className="flex-1 justify-start items-center w-full h-full relative">
          <View className="md:hidden mt-4 relative max-w-2xl w-full mx-auto">
            <Image
              source={require("../assets/imperial-treasure.png")}
              className="w-[127px] h-[17px] block my-auto self-center"
            />
          </View>

          <ScrollView className="w-full flex-1">
            <View className="flex items-center">
              <Header />
              <ImageBackground
                source={require("../assets/logo.png")}
                resizeMode="contain"
                className="md:hidden w-[83px] h-[150px] mt-5"
              ></ImageBackground>

              <View className="w-full max-w-md text-left px-8 py-6 md:mt-10 h-auto md:bg-white mb-20">
                <Text className=" text-2xl mt-6 mb-4 font-Crimson">{i18n.t("login.login")}</Text>
                {/* <Text>{i18n.t("login")}</Text> */}
                {/* <Text>Current locale: {i18n.locale}</Text> */}
                {/* <Text>Device locale: {Localization.locale}</Text> */}
                <View className="border-b w-[calc(100% + 96px)] mx-[-32px] border-[#E5E6E8] flex flex-row justify-start">
                  <TouchableOpacity
                    className={loginMethod == "mobile" ? "ml-8 border-b border-brandgold" : "ml-8 "}
                    onPress={() => {
                      setLoginMethod("mobile");
                    }}
                  >
                    <Text
                      className={
                        loginMethod == "mobile"
                          ? "uppercase text-sm font-medium text-brandgold px-4 py-2"
                          : "uppercase text-sm font-medium text-[#4E5969] px-4 py-2"
                      }
                    >
                      {i18n.t("login.mobile")}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className={loginMethod == "email" ? "border-b border-brandgold" : ""}
                    onPress={() => {
                      setLoginMethod("email");
                    }}
                  >
                    <Text
                      className={
                        loginMethod == "email"
                          ? "uppercase text-sm font-medium text-brandgold px-4 py-2"
                          : "uppercase text-sm font-medium text-[#4E5969] px-4 py-2"
                      }
                    >
                      {i18n.t("login.email")}
                    </Text>
                  </TouchableOpacity>
                </View>

                {loginMethod == "mobile" && (
                  <>
                    <Text className="color-[#4E5969] text-s mt-6 font-semibold hover:border-none font-NunitoSans uppercase">
                      {i18n.t("login.mobile2")}
                    </Text>
                    <View className="relative">
                      <IntlPhoneInput
                        mask={""}
                        onChangeText={onChangeMobile}
                        defaultCountry={API_REGION}
                        containerStyle={{
                          flex: 1,
                          flexDirection: "row",
                          marginTop: "4px",
                        }}
                        flagStyle={{ display: "none" }}
                        phoneInputStyle={{
                          color: "#86909C",
                          height: 36,
                          width: "100%",
                          borderWidth: 1,
                          borderColor: "#F2F3F5",
                          backgroundColor: "#F2F3F5",
                          paddingHorizontal: 15,
                        }}
                        dialCodeTextStyle={{
                          color: "#86909C",
                          height: 36,
                          lineHeight: 36,
                          width: "100%",
                          borderWidth: 1,
                          borderColor: "#F2F3F5",
                          backgroundColor: "#F2F3F5",
                          paddingHorizontal: 15,
                        }}
                        placeholder={phone}
                      />
                    </View>
                  </>
                )}

                {loginMethod == "email" && (
                  <>
                    <Text className="color-[#4E5969] text-s mt-6 font-semibold hover:border-none font-NunitoSans uppercase">
                      {i18n.t("login.email")}
                    </Text>
                    <View className="relative">
                      <TextInput
                        placeholder=""
                        onChangeText={setUser}
                        value={user}
                        className="h-9 w-full bg-[#F2F3F5] color-[#121212] mt-1 px-4"
                      ></TextInput>
                      <TouchableOpacity
                        onPress={() => {
                          resetUser();
                        }}
                        className="absolute top-[14px] right-[10px]"
                      >
                        <Image
                          source={require("../assets/icons/icon-delete.png")}
                          className="w-[17px] h-[17px]"
                        />
                      </TouchableOpacity>
                    </View>
                  </>
                )}

                <Text className="color-[#4E5969] text-s mt-6 font-semibold hover:border-none font-NunitoSans uppercase">
                  {i18n.t("login.password")}
                </Text>

                <View className="relative">
                  <TextInput
                    placeholder=""
                    onChangeText={setPasword}
                    value={password}
                    secureTextEntry={securePassword}
                    returnKeyType="go"
                    className="h-9 w-full bg-[#F2F3F5] color-[#121212] mt-1 px-4"
                  ></TextInput>
                  <TouchableOpacity
                    onPress={() => {
                      showHidePassword();
                    }}
                    className="absolute top-[14px] right-[10px]"
                  >
                    {securePassword && (
                      <Image
                        className="w-[17px] h-[17px]"
                        source={require("../assets/icons/icon-carbon-view-off.png")}
                      />
                    )}
                    {!securePassword && (
                      <Image
                        className="w-[17px] h-[17px]"
                        source={require("../assets/icons/icon-carbon-view.png")}
                      />
                    )}
                  </TouchableOpacity>
                </View>

                {formErr ? <Text className="color-red-500 my-1">{errMsg}</Text> : null}

                <View className="flex flex-row justify-start mt-5 ">
                  <Image
                    className="w-[14px] h-[14px] mr-1"
                    source={require("../assets/icons/icon-lock.png")}
                  />
                  <Text
                    className="text-black text-xs underline font-PingFangSC"
                    onPress={() => navigation.push("ForgetPassword")}
                  >
                    {" "}
                    {i18n.t("login.forgotpw")}
                  </Text>
                </View>

                <Captcha returnStatus={(status) => setRecaptchaValid(status)} />
                {recaptchaErr && (
                  <Text className="color-red-500 my-1">{i18n.t("signUp.captcha_errMsg")}</Text>
                )}

                <TouchableOpacity
                  className="w-full h-10 max-h-10 bg-brandgold mt-4"
                  onPress={() => {
                    signIn();
                  }}
                >
                  <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC uppercase">
                    {i18n.t("login.login")}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  className="w-full h-10 max-h-10 bg-white border border-brandgold mt-3"
                  onPress={() => {
                    goSignUp();
                  }}
                >
                  <Text className="text-brandgold w-full h-10 leading-10 text-center font-normal font-PingFangSC uppercase">
                    {i18n.t("login.register")}
                  </Text>
                </TouchableOpacity>

                <Text className="text-[#1111111] text-xs mt-2 font-NunitoSans">
                  {i18n.t("login.txt")}
                  <Text
                    className="underline text-black"
                    onPress={() => navigation.push("Privilege")}
                  >
                    {i18n.t("login.txt_2")}
                  </Text>
                </Text>
              </View>
            </View>
          </ScrollView>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Login;
