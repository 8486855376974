import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Image,
  ImageBackground,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  Button,
} from "react-native";

function HeaderOnly(props) {
  return (
    <View className="w-full bg-white items-center justify-center flew-row p-4 relative content-center md:hidden">
      <Text className="text-[#121212] text-lg font-bold">{props.title}</Text>
    </View>
  );
}

export default HeaderOnly;
