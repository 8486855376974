import React, { useState } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  ImageBackground,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  TextInput,
  Input,
  ActivityIndicator,
  Modal,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Header from "../components/header";
import Footer from "../components/footer";
import RNPickerSelect from "react-native-picker-select";
import i18n from "../components/i18n";
import AsyncStorage from "../components/AsyncStorage";
import CookieConsent from "../components/CookieConsent";
import { API_MIDDLEWARE, API_REGION } from "@env";
import axios from "axios";
import NewMobileHeader from "../components/newMobileHeader";

const LanguageSetting = () => {
  const navigation = useNavigation();

  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // const switchLang = (lang) => {
  //   setLanguageErr(false);
  //   if (language == "") {
  //     setLanguageErr(true);
  //     return;
  //   }

  //   i18n.locale = lang;

  //   AsyncStorage.storeData("lang", lang);
  //   // setTimeout(()=>{
  //     // location.reload();
  //     navigation.goBack();
  //   // }, 1000)
  // };

  const switchLang = (lang) => {
    setIsLoading(true);
    setLanguageErr(false);
    if (language == "") {
      setLanguageErr(true);
      return;
    }

    i18n.locale = lang;

     AsyncStorage.storeData("lang", lang);
    // navigation.goBack();
    location.reload();
    setIsLoading(false);
  };

  return (
    <View className="flex-1 md:bg-[#efefef]">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="justify-start items-center w-full h-full"
      >
        <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader
            title={i18n.t("languageSetting.title")}
            logout={"Login"}
          />

          <ScrollView className="w-full flex-1 h-auto">
            <View className="w-11/12 md:w-4/5 max-w-7xl h-auto m-auto bg-white p-5 md:mt-6 md:flex">
              <View className="flex">
                <Text className=" text-base text-2xl md:text-3xl text-brandgold md:text-black font-semibold font-Crimson">
                  {i18n.t("languageSetting.setting")}
                </Text>
              </View>

              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

              <Text className="text-[#4E5969] text-xs mt-4 mb-2 font-PingFangSC">
                {i18n.t("languageSetting.language_preference")}
              </Text>

              <RNPickerSelect
                value={language}
                onValueChange={(value) => setLanguage(value)}
                items={[
                  {
                    label: i18n.t("languageSetting.language_preference_opt2"),
                    value: "en",
                  },
                  {
                    label: i18n.t("languageSetting.language_preference_opt3"),
                    value: "zh",
                  },
                ]}
                placeholder={{
                  label: i18n.t("languageSetting.language_preference_opt1"),
                  value: "",
                }}
                style={pickerSelectStyles}
              />

              {languageErr && (
                <Text className="color-red-500 my-1">
                  {i18n.t("languageSetting.language_preference_errMsg")}
                </Text>
              )}

              {/* <Text>Checking-selected language: {language}</Text> */}

              <View className="md:flex md:flex-row md:flex-wrap justify-between pb-4 mt-6">
                <TouchableOpacity
                  className="w-auto h-8 max-h-8 bg-brandgold md:mt-8 mt-4 px-4 "
                  onPress={() => switchLang(language)}
                >
                  <Text className="text-white w-full 10h- leading-8 text-center font-PingFangSC">
                    {i18n.t("languageSetting.submit")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputWeb: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
});

export default LanguageSetting;
