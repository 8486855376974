import React, { useState, useRef, useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Modal,
  TextInput,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import InsideHeader from "../components/insideHeader";
import AsyncStorage from "../components/AsyncStorage";
import { API_MIDDLEWARE } from "@env";
import i18n from "../components/i18n";
import axios from "axios";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

let timer = () => { };

const UpdateEmail = () => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(true);
  const [memberCode, setMemberCode] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [member, setMember] = useState("");
  const [token, setToken] = useState("");
  const [tokenErr, setTokenErr] = useState(false);
  const [otpCount, setOtpCount] = useState(60);
  const [otpStatus, setOtpStatus] = useState(0);
  const [disableItem, setDisableItem] = useState(false);
  const [otpSign, setOtpSign] = useState("");
  const [successOverlay, setSuccessOverlay] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [emailUniqueErr, setEmailUniqueErr] = useState(false);

  useEffect(() => {
    startTimer();
    return () => clearTimeout(timer);
  });

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          console.log(customer);

          setMember(customer);

          setIsLoading(false);
        });
      }
    });
  }, []);

  const sendToken = () => {
    let errors = [];
    setEmailErr(false);
    setEmailUniqueErr(false);

    setIsLoading(true);

    if (!validEmail(email)) {
      errors.push("err");
      setEmailErr(true);
    }

    if (!errors.length) {
      let data = {
        email: email,
      };

      checkUnique(data, (unique) => {
        if (unique.status == "success") {
          let data = {
            channel: "email",
            target: email,
          };

          sendOTP(data, (otp) => {
            if (otp.status == "success") {
              setOtpSign(otp.signature);
              setDisableItem(true);
              setOtpStatus(1);
              start();
            } else {
              alert(otp.message);
            }
          });

          setIsLoading(false);
        } else {
          setEmailUniqueErr(true);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  const updateEmail = () => {
    setTokenErr(false);

    if (token == "") {
      setTokenErr(true);
    } else {
      setIsLoading(true);

      let data = {
        channel: "email",
        target: email,
        sign: otpSign,
        code: token,
      };

      verifyOTP(data, (otp) => {
        if (otp.status == "success") {
          let updateData = {
            code: member.code,
            email: email,
          };

          updateCust(updateData, (update) => {
            if (update.status == "success") {
              setSuccessOverlay(true);
              setIsLoading(false);
            } else {
              alert("Update Error");
              setIsLoading(false);
            }
          });
        } else {
          setTokenErr(true);
          setIsLoading(false);
        }
      });
    }
  };

  const startTimer = () => {
    timer = setTimeout(() => {
      if (otpCount <= 0) {
        clearTimeout(timer);
        setDisableItem(false);
        return false;
      }
      setOtpCount(otpCount - 1);
    }, 1000);
  };

  const start = () => {
    setOtpCount(60);
    clearTimeout(timer);
    startTimer();
  };

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const validEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const updateCust = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "updateCust");
    formData.append("code", data.code);
    formData.append("email", data.email);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const checkUnique = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "checkDup");
    formData.set("email", data.email);
    formData.set("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const sendOTP = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "sendotp");
    formData.set("channel", data.channel);
    formData.set("target", data.target);
    formData.set("region", memberRegion);

    if (data.country_code) {
      formData.set("country_code", data.country_code);
    }

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const verifyOTP = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "verifyotp");
    formData.set("channel", data.channel);
    formData.set("target", data.target);
    formData.set("sign", data.sign);
    formData.set("code", data.code);
    formData.set("region", memberRegion);

    if (data.country_code) {
      formData.set("country_code", data.country_code);
    }

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  return (
    <SafeAreaView className="min-h-full bg-white md:bg-[#F5F5F5]">
      {isLoading && (
        <View
          className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-screen"
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHeader />
      <NewMobileHeader />
      <MobileHeader
        title={i18n.t("updateEmail.title")}
        backTo={"EditProfile"}
      />
      <ScrollView className="flex-1 h-auto">
        <View className="hidden md:flex md:w-4/5 max-w-7xl m-auto mt-6 mb-4 flex-row items-center">
          <Image
            className="w-4 h-4 mr-1"
            source={require("../assets/icons/icon-home.png")}
          />
          <TouchableOpacity onPress={() => navigation.push("Profile")}>
            <Text className="text-sm font-normal text-[#4E5969] font-PingFangSC">
              {i18n.t("updateEmail.member_profile")}
            </Text>
          </TouchableOpacity>
          <Text> / </Text>
          <Text className="font-PingFangSC">
            {i18n.t("updateEmail.updateEmail")}
          </Text>
        </View>
        <View className="flex w-full md:w-4/5 max-w-7xl m-auto bg-white p-5 flex-col">
          <View>
            <Text className="hidden md:flex text-2xl font-semibold text-black font-Crimson">
              {i18n.t("updateEmail.updateEmail")}
            </Text>
          </View>

          <View className="flex flex-col flex-wrap items-start justify-between pb-4">
            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
              {i18n.t("updateEmail.new_email")}
            </Text>

            <View className="w-full flex flex-row justify-around">
              <TextInput
                placeholder=""
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                value={email}
                onChangeText={setEmail}
              ></TextInput>
              <TouchableOpacity
                className="h-9 w-[130px] bg-[#F2F3F5] px-4 ml-3"
                onPress={() => sendToken()}
                disabled={disableItem}
              >
                {disableItem && (
                  <Text className="text-gray-400 w-full text-sm h-9 leading-9 text-center font-semibold font-PingFangSC">
                    {i18n.t("updateEmail.sent")}({otpCount})
                  </Text>
                )}
                {!disableItem && (
                  <Text className="text-[#121212] w-full text-sm h-9 leading-9 text-center font-semibold font-PingFangSC">
                    {i18n.t("updateEmail.send_token")}
                  </Text>
                )}
              </TouchableOpacity>
            </View>

            {emailErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("updateEmail.email_errMsg1")}
              </Text>
            )}
            {emailUniqueErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("updateEmail.email_errMsg2")}
              </Text>
            )}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">
              {i18n.t("updateEmail.token")}
            </Text>

            <View className="w-full flex flex-row justify-around">
              <TextInput
                placeholder=""
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                value={token}
                onChangeText={setToken}
              ></TextInput>
            </View>

            {tokenErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("updateEmail.token_error")}
              </Text>
            )}

            <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
              <TouchableOpacity
                className="w-full md:w-auto md:max-w-[125px] h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4"
                onPress={() => updateEmail()}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">
                  {i18n.t("updateEmail.update")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>

      {successOverlay && (
        <View
          className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
          style={{ position: "fixed" }}
        >
          <View className="w-11/12 max-w-xs bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => {
                setSuccessOverlay(false);
                navigation.push("EditProfile");
              }}
            >
              <Image
                className="w-5 h-5"
                source={require("../assets/btn-close.png")}
              />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-8 uppercase font-Crimson">
              {i18n.t("updateEmail.update_success")}
            </Text>

            <View className="text-right flex items-end mb-8">
              <TouchableOpacity
                className="w-full h-8 max-h-8 bg-brandgold mt-4 mr-0"
                onPress={() => {
                  setSuccessOverlay(false);
                  navigation.push("EditProfile");
                }}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                  {i18n.t("updateEmail.done")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      <Footer />
      <CookieConsent />
    </SafeAreaView>
  );
};

export default UpdateEmail;
