import AsyncStorage from '@react-native-async-storage/async-storage';

export default {
    async storeData(key, value) {
        try {
            //const jsonValue = JSON.stringify(value)
            return await AsyncStorage.setItem(key, value)
        } catch (e) {
            // saving error
            console.log(e);
            return e
        }
    },

    async getData(key) {
        try {
            const value = await AsyncStorage.getItem(key)
            if (value !== null) {
                // value previously stored
                return value;
            }
        } catch (e) {
            // error reading value
            console.log(e);
            return e;
        }
    },

    async removeValue(key) {
        try {
            await AsyncStorage.removeItem(key)
        } catch (e) {
            // remove error
            console.log(e);
            return e;
        }

        console.log('Done.')
    }
}