import React, { useState, useRef, useEffect } from "react";
import { StyleSheet, View, ScrollView, Text, Image, TouchableOpacity, SafeAreaView, ActivityIndicator, TextInput, Modal, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import RNPickerSelect from "react-native-picker-select";
import Checkbox from "expo-checkbox";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import InsideHeader from "../components/insideHeader";
import AsyncStorage from "../components/AsyncStorage";
import moment from "moment";
import { API_MIDDLEWARE } from "@env";
import i18n from "../components/i18n";
import axios from "axios";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const EditProfile = () => {
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [editType, setEditType] = useState("general-information");
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [lastname, setLastname] = useState("");
  const [lastnameErr, setLastnameErr] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [firstnameErr, setFirstnameErr] = useState(false);
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState(false);
  const [successOverlay, setSuccessOverlay] = useState(false);
  const [pPassword, setPPassword] = useState("");
  const [pPasswordErr, setPPasswordErr] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [passwordFormatErr, setPasswordFormatErr] = useState(false);
  const [passwordOverlay, setPasswordOverlay] = useState(false);
  const [eyeIcon, setEyeIcon] = useState("eye");
  const [securePrePassword, setSecurePrePassword] = useState(true);
  const [secureNewPassword, setSecureNewPassword] = useState(true);
  const [secureNewComfirmedPassword, setSecureNewComfirmedPassword] = useState(true);
  const [optEmail, setOptEmail] = useState(false);
  const [optSMS, setOptSMS] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          console.log(customer);

          setMember(customer);
          setTitle(customer.title);
          setFirstname(customer.first_name);
          setLastname(customer.last_name);

          if (customer.gender == null || customer.gender == "null") {
            setGender("");
          } else {
            setGender(customer.gender);
          }

          if (customer.email_optin == 1) {
            setOptEmail(true);
          }

          if (customer.mobile_optin == 1) {
            setOptSMS(true);
          }

          if (customer.language == null || customer.language == "null") {
            setLanguage("");
          } else {
            setLanguage(customer.language);
          }

          setIsLoading(false);
        });
      }
    });
  }, []);

  const submitUpdate = () => {
    setIsLoading(true);
    setTitleErr(false);
    setFirstnameErr(false);
    setLastnameErr(false);
    setGenderErr(false);
    setLanguageErr(false);

    let errors = [];

    if (title == "") {
      errors.push("err");
      setTitleErr(true);
    }

    if (gender == "") {
      errors.push("err");
      setGenderErr(true);
    }

    if (firstname == "") {
      errors.push("err");
      setFirstnameErr(true);
    }

    if (lastname == "") {
      errors.push("err");
      setLastnameErr(true);
    }

    if (language == "") {
      errors.push("err");
      setLanguageErr(true);
    }

    if (!errors.length) {
      let data = {
        code: member.code,
        title: title, // pass specific title value according to language preference; e.g. Mr = 先生
        firstname: firstname,
        lastname: lastname,
        gender: gender,
        language: language,
      };

      updateCust(data, (cust) => {
        if (cust.status == "success") {
          setSuccessOverlay(true);
          setIsLoading(false);
        } else {
          alert("error");
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  const confirmUpdatePassword = () => {
    let errors = [];
    setPPasswordErr(false);
    setPasswordErr(false);
    setConfirmPasswordErr(false);
    setPasswordFormatErr(false);

    if (pPassword == "") {
      errors.push("err");
      setPPasswordErr(true);
    }

    if (password == "") {
      errors.push("err");
      setPasswordErr(true);
    } else if (password != confirmPassword) {
      errors.push("err");
      setConfirmPasswordErr(true);
    } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/)) {
      errors.push("err");
      setPasswordFormatErr(true);
    }

    if (!errors.length) {
      setIsLoading(true);

      let loginData = {
        mobile: member.country_code + member.mobile,
        password: pPassword,
      };

      loginCheck(loginData, (check) => {
        if (check.status != "success") {
          console.log("login error");
          setPPasswordErr(true);
          setIsLoading(false);
        } else {
          let updateData = {
            code: member.code,
            password: password,
          };

          updatePassword(updateData, (update) => {
            if (update.status == "success") {
              setIsLoading(false);
              setPPassword("");
              setPassword("");
              setConfirmPassword("");
              setPasswordOverlay(true);
            } else {
              console.log(update);
            }
          });
        }
      });
    }
  };

  const UpdateOptStatus = () => {
    setIsLoading(true);

    let dataOptinSMS = {
      code: member.code,
      channel: "Mobile",
    };

    let dataOptinEmail = {
      code: member.code,
      channel: "Email",
    };

    if (optEmail) {
      optIn(dataOptinEmail, (optin) => {
        if (optin.status == "success") {
          console.log("optin email success");
        }
      });
    } else {
      optOut(dataOptinEmail, (optout) => {
        if (optout.status == "success") {
          console.log("optout email success");
        }
      });
    }

    if (optSMS) {
      optIn(dataOptinSMS, (optin) => {
        if (optin.status == "success") {
          console.log("optin sms success");
        }
      });
    } else {
      optOut(dataOptinSMS, (optout) => {
        if (optout.status == "success") {
          console.log("optout sms success");
        }
      });
    }

    setSuccessOverlay(true);
    setIsLoading(false);
  };

  const loginCheck = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "login");
    formData.append("mobile", data.mobile);
    formData.append("password", data.password);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
        callback(false);
      });
  };

  const updatePassword = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "updateCust");
    formData.append("code", data.code);
    formData.append("customer_password", data.password);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const updateCust = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "updateCust");
    formData.append("code", data.code);
    formData.append("title", data.title);
    formData.append("first_name", data.firstname);
    formData.append("last_name", data.lastname);
    formData.append("gender", data.gender);
    formData.append("language", data.language);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const optIn = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "custOptin");
    formData.set("code", data.code);
    formData.set("channel", data.channel);
    formData.set("optin_reason", "Web Edit Profile Form Optin");
    formData.set("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const optOut = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "custOptout");
    formData.set("code", data.code);
    formData.set("channel", data.channel);
    formData.set("optout_reason", "Web Edit Profile Form Optout");
    formData.set("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const dateFormat = (date) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
  };

  const birthDateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD-MMM-YYYY");
    } else {
      return "N/A";
    }
  };

  const showHidePrePassword = () => {
    if (eyeIcon == "eye") {
      setEyeIcon("eye-slash");
      setSecurePrePassword(false);
    } else {
      setEyeIcon("eye");
      setSecurePrePassword(true);
    }
  };

  const showHideNewPassword = () => {
    if (eyeIcon == "eye") {
      setEyeIcon("eye-slash");
      setSecureNewPassword(false);
    } else {
      setEyeIcon("eye");
      setSecureNewPassword(true);
    }
  };

  const showHideNewConfirmedPassword = () => {
    if (eyeIcon == "eye") {
      setEyeIcon("eye-slash");
      setSecureNewComfirmedPassword(false);
    } else {
      setEyeIcon("eye");
      setSecureNewComfirmedPassword(true);
    }
  };

  const changeLanguage = (languageValue, title) => {
    setLanguage(languageValue);

    if (languageValue == "en-us") {
      if (title == "先生") {
        setTitle("Mr");
      }
      if (title == "太太") {
        setTitle("Mrs");
      }
      if (title == "女士") {
        setTitle("Mdm");
      }
      if (title == "小姐") {
        setTitle("Miss");
      }
      if (title == "博士") {
        setTitle("Dr");
      }
    } else {
      if (title == "Mr") {
        setTitle("先生");
      }
      if (title == "Mrs") {
        setTitle("太太");
      }
      if (title == "Mdm") {
        setTitle("女士");
      }
      if (title == "Miss") {
        setTitle("小姐");
      }
      if (title == "Dr") {
        setTitle("博士");
      }
    }
  };

  const TextLink = ({ text, link, splitBy, ...rest }) => {
    let parts = text.split(splitBy);
    return (
      <Text {...rest}>
        <Text>{parts[0]}</Text>
        <Text className="underline cursor-pointer" onPress={() => Linking.openURL(link)}>
          {parts[1]}
        </Text>
        <Text>{parts[2]}</Text>
      </Text>
    );
  };

  return (
    <SafeAreaView className="min-h-full bg-white md:bg-[#F5F5F5]" pointerEvents={!isLoading ? "auto" : "none"}>
      {isLoading && (
        <View className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-screen" pointerEvents={"none"}>
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHeader />
      <NewMobileHeader />
      <MobileHeader title={i18n.t("editProfile.title")} backTo={"Profile"} />
      <ScrollView className="flex-1 h-auto">
        <View className="hidden md:flex md:w-4/5 max-w-7xl m-auto mt-6 mb-4 flex-row items-center">
          <Image className="w-4 h-4 mr-1" source={require("../assets/icons/icon-home.png")} />
          <TouchableOpacity onPress={() => navigation.push("Profile")}>
            <Text className="text-base font-normal text-[#4E5969] font-PingFangSC">{i18n.t("editProfile.member_profile")}</Text>
          </TouchableOpacity>
          <Text> / </Text>
          <Text className="font-PingFangSC">{i18n.t("editProfile.editProfile")}</Text>
        </View>
        <View className="flex w-full md:w-4/5 max-w-7xl m-auto bg-white p-5 flex-col">
          <View>
            <Text className="hidden md:block text-2xl font-semibold text-black">{i18n.t("editProfile.member_profile")}</Text>
          </View>
          <View className="w-full flex flex-row flex-wrap items-center justify-between border-b border-b-[#E5E6E8] mt-5">
            <View className="flex flex-row items-center justify-start">
              <TouchableOpacity onPress={() => setEditType("general-information")}>
                <Text className={editType == "general-information" ? "text-base font-normal text-[#BD9B60] py-2 mr-8 border-b border-b-[#BD9B60] font-PingFangSC" : "text-base font-normal text-[#4E5979] py-2 mr-8 font-PingFangSC"}>{i18n.t("editProfile.general")}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setEditType("security")}>
                <Text className={editType == "security" ? "text-base font-normal text-[#BD9B60] py-2 mr-8 border-b border-b-[#BD9B60] font-PingFangSC" : "text-base font-normal text-[#4E5979] py-2 mr-8 font-PingFangSC"}>{i18n.t("editProfile.security")}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setEditType("subscriptions")}>
                <Text className={editType == "subscriptions" ? "text-base font-normal text-[#BD9B60] py-2 mr-8 border-b border-b-[#BD9B60] font-PingFangSC" : "text-base font-normal text-[#4E5979] py-2 mr-8 font-PingFangSC"}>{i18n.t("editProfile.subscriptions")}</Text>
              </TouchableOpacity>
            </View>
          </View>

          {editType == "general-information" && (
            <>
              <View className="flex flex-row flex-wrap items-start justify-between pb-4">
                <View className="w-full md:w-[48%]">
                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.salutation")}</Text>

                  {/* <Text className="text-red-500">
                    Title Value Checking : {title}
                  </Text> */}

                  <View>
                    <RNPickerSelect
                      value={title}
                      onValueChange={(value) => setTitle(value)}
                      items={[
                        {
                          label: i18n.t("editProfile.salutation_opt2"),
                          value: "Mr",
                        },
                        {
                          label: i18n.t("editProfile.salutation_opt3"),
                          value: "Mrs",
                        },
                        {
                          label: i18n.t("editProfile.salutation_opt4"),
                          value: "Mdm",
                        },
                        {
                          label: i18n.t("editProfile.salutation_opt5"),
                          value: "Miss",
                        },
                        {
                          label: i18n.t("editProfile.salutation_opt6"),
                          value: "Dr",
                        },
                      ]}
                      placeholder={{
                        label: i18n.t("editProfile.salutation_opt1"),
                        value: "",
                      }}
                      style={pickerSelectStyles}
                      useNativeAndroidPickerStyle={false}
                    />
                  </View>

                  {/* {language == "zh-cn" && (
                    <View>
                      <RNPickerSelect
                        value={title}
                        onValueChange={(value) => setTitle(value)}
                        items={[
                          {
                            label: i18n.t("editProfile.salutation_opt2"),
                            value: "先生",
                          },
                          {
                            label: i18n.t("editProfile.salutation_opt3"),
                            value: "太太",
                          },
                          {
                            label: i18n.t("editProfile.salutation_opt4"),
                            value: "女士",
                          },
                          {
                            label: i18n.t("editProfile.salutation_opt5"),
                            value: "小姐",
                          },
                          {
                            label: i18n.t("editProfile.salutation_opt6"),
                            value: "博士",
                          },
                        ]}
                        placeholder={{
                          label: i18n.t("editProfile.salutation_opt1"),
                          value: "",
                        }}
                        style={pickerSelectStyles}
                        useNativeAndroidPickerStyle={false}
                      />
                    </View>
                  )} */}
                  {/* <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" ></TextInput> */}

                  {titleErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.salutation_errMsg")}</Text>}

                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.gender")}</Text>
                  <RNPickerSelect
                    value={gender}
                    onValueChange={(value) => setGender(value)}
                    items={[
                      {
                        label: i18n.t("editProfile.gender_opt2"),
                        value: "male",
                      },
                      {
                        label: i18n.t("editProfile.gender_opt3"),
                        value: "female",
                      },
                    ]}
                    placeholder={{
                      label: i18n.t("editProfile.gender_opt1"),
                      value: "",
                    }}
                    style={pickerSelectStyles}
                    useNativeAndroidPickerStyle={false}
                  />

                  {genderErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.gender_errMsg")}</Text>}

                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.first_name")}</Text>
                  <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={firstname} onChangeText={setFirstname}></TextInput>

                  {firstnameErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.first_name_errMsg")}</Text>}

                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.last_name")}</Text>
                  <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={lastname} onChangeText={setLastname}></TextInput>

                  {lastnameErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.last_name_errMsg")}</Text>}
                </View>

                <View className="w-full md:w-[48%]">
                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.birth")}</Text>
                  <Text className="text-[#4E5969] text-base ml-2 mb-1 font-Crimson h-[34px] leading-[34px]">{birthDateFormat(member.birthday)}</Text>

                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.mobile")}</Text>
                  <View className="flex flex-row ml-2 mb-1 h-[34px]">
                    <Text className="text-[#4E5969] text-base font-Crimson leading-[34px]">
                      +{member.country_code}
                      {member.mobile}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.push("UpdateMobile");
                      }}
                    >
                      <Text className="text-brandgold text-base font-Crimson ml-2 leading-[34px]">{i18n.t("editProfile.mobile_change")}</Text>
                    </TouchableOpacity>
                  </View>

                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.email")}</Text>
                  <View className="flext flex-row ml-2 mb-1 h-[34px]">
                    <Text className="text-[#4E5969] text-base font-Crimson leading-[34px]">{member.email}</Text>
                    <TouchableOpacity
                      onPress={() => {
                        navigation.push("UpdateEmail");
                      }}
                    >
                      <Text className="text-brandgold text-base font-Crimson ml-2 leading-[34px]">{i18n.t("editProfile.email_change")}</Text>
                    </TouchableOpacity>
                  </View>

                  <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.language")}</Text>
                  <RNPickerSelect
                    value={language}
                    onValueChange={(value) => changeLanguage(value, title)}
                    items={[
                      {
                        label: i18n.t("editProfile.language_opt2"),
                        value: "en-us",
                      },
                      {
                        label: i18n.t("editProfile.language_opt3"),
                        value: "zh-cn",
                      },
                    ]}
                    placeholder={{
                      label: i18n.t("editProfile.language_opt1"),
                      value: "",
                    }}
                    style={pickerSelectStyles}
                    useNativeAndroidPickerStyle={false}
                  />

                  {languageErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.language_opt1")}</Text>}
                </View>

                <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                  <TouchableOpacity className="w-full md:w-auto md:max-w-[125px] h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => submitUpdate()}>
                    <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC text-base pt-1">{i18n.t("editProfile.update")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </>
          )}

          {editType == "security" && (
            <>
              <View className="flex flex-col flex-wrap items-start justify-between pb-4">
                <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.previous_password")}</Text>

                <View className="relative w-full">
                  <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={securePrePassword} value={pPassword} onChangeText={setPPassword}></TextInput>

                  <TouchableOpacity
                    onPress={() => {
                      showHidePrePassword();
                    }}
                    className="absolute top-[14px] right-[10px]"
                  >
                    {securePrePassword && <Image className="w-[17px] h-[13px]" source={require("../assets/icons/icon-carbon-view-off.png")} />}
                    {!securePrePassword && <Image className="w-[17px] h-[13px]" source={require("../assets/icons/icon-carbon-view.png")} />}
                  </TouchableOpacity>
                </View>

                {pPasswordErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.previous_password_errMsg")}</Text>}

                <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.new_password")}</Text>

                <View className="relative w-full">
                  <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={secureNewPassword} value={password} onChangeText={setPassword}></TextInput>

                  <TouchableOpacity
                    onPress={() => {
                      showHideNewPassword();
                    }}
                    className="absolute top-[14px] right-[10px]"
                  >
                    {secureNewPassword && <Image className="w-[17px] h-[13px]" source={require("../assets/icons/icon-carbon-view-off.png")} />}
                    {!secureNewPassword && <Image className="w-[17px] h-[13px]" source={require("../assets/icons/icon-carbon-view.png")} />}
                  </TouchableOpacity>
                </View>

                <Text className="text-base mt-2">{i18n.t("editProfile.new_password_errMsg2")}</Text>

                {passwordErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.new_password_errMsg1")}</Text>}
                {passwordFormatErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.new_password_errMsg2")}​</Text>}

                <Text className="text-[#4E5969] text-base mt-4 mb-1 font-PingFangSC">{i18n.t("editProfile.confirm_password")}</Text>

                <View className="relative w-full">
                  <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={secureNewComfirmedPassword} value={confirmPassword} onChangeText={setConfirmPassword}></TextInput>

                  <TouchableOpacity
                    onPress={() => {
                      showHideNewConfirmedPassword();
                    }}
                    className="absolute top-[14px] right-[10px]"
                  >
                    {secureNewComfirmedPassword && <Image className="w-[17px] h-[13px]" source={require("../assets/icons/icon-carbon-view-off.png")} />}
                    {!secureNewComfirmedPassword && <Image className="w-[17px] h-[13px]" source={require("../assets/icons/icon-carbon-view.png")} />}
                  </TouchableOpacity>
                </View>

                {confirmPasswordErr && <Text className="color-red-500 my-1">{i18n.t("editProfile.confirm_password_errMsg")}</Text>}

                <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                  <TouchableOpacity className="w-full md:w-auto md:max-w-[125px] h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => confirmUpdatePassword()}>
                    <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC text-base pt-1">{i18n.t("editProfile.update")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </>
          )}

          {editType == "subscriptions" && (
            <>
              <View className="flex flex-col flex-wrap items-start justify-between pb-4">
                <Text className="text-base mt-6">{i18n.t("editProfile.subscriptions_txt1")}</Text>
                <Text className="text-base mt-2 mb-3 font-bold">{i18n.t("editProfile.subscriptions_txt2")}</Text>

                <View className="flex-row items-center mb-4">
                  <Checkbox className="mr-3" value={optEmail} onValueChange={setOptEmail} color={optEmail ? "#000000" : undefined} />
                  <Text onPress={() => setOptEmail(!optEmail)}>{i18n.t("editProfile.by_email")}</Text>
                </View>

                <View className="flex-row items-center mb-4">
                  <Checkbox className="mr-3" value={optSMS} onValueChange={setOptSMS} color={optSMS ? "#000000" : undefined} />
                  <Text onPress={() => setOptSMS(!optSMS)}>{i18n.t("editProfile.by_mobile")}</Text>
                </View>

                <Text className="text-base mt-6">{i18n.t("editProfile.subscriptions_txt3")}</Text>

                <Text className="text-base mt-6">
                  <TextLink link={"https://members.imperialtreasure.com.hk"} text={i18n.t("editProfile.subscriptions_txt4")} splitBy={"<link>"} />
                </Text>

                <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                  <TouchableOpacity className="w-full md:w-auto md:max-w-[125px] h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => UpdateOptStatus()}>
                    <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC text-base pt-1">{i18n.t("editProfile.update")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </>
          )}
        </View>
      </ScrollView>

      {successOverlay && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-xs bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => {
                setSuccessOverlay(false);
                navigation.push("EditProfile");
              }}
            >
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-8 uppercase font-Crimson">{i18n.t("editProfile.update_success")}</Text>

            <View className="text-right flex items-end mb-8">
              <TouchableOpacity
                className="w-full h-8 max-h-8 bg-brandgold mt-4 mr-0"
                onPress={() => {
                  setSuccessOverlay(false);
                  navigation.push("Profile");
                }}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("editProfile.done")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      {passwordOverlay && (
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-xs bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => {
                setPasswordOverlay(false);
                navigation.push("EditProfile");
              }}
            >
              <Image className="w-5 h-5" source={require("../assets/btn-close.png")} />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-8 uppercase font-Crimson">{i18n.t("editProfile.password_update_success")}</Text>

            <View className="text-right flex items-end mb-8">
              <TouchableOpacity
                className="w-full h-8 max-h-8 bg-brandgold mt-4 mr-0"
                onPress={() => {
                  setPasswordOverlay(false);
                  navigation.push("Profile");
                }}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">{i18n.t("editProfile.done")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      <Footer />
      <CookieConsent />
    </SafeAreaView>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 32,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    marginBottom: 6,
  },
  inputAndroid: {
    color: "#86909C",
    height: 38,
    fontSize: 15,
    lineHeight: 38,
    width: "100%",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
    borderWidth: 1,
    paddingHorizontal: 15,
  },
  inputWeb: {
    color: "#86909C",
    height: 32,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    marginBottom: 6,
  },
});

export default EditProfile;
