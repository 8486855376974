import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Animated,
  TouchableOpacity,
  ScrollView,
  Modal,
  Dimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Checkbox from "expo-checkbox";
import AsyncStorage from "../components/AsyncStorage";
import { WebView } from "react-native-webview";

const CookieConsent = () => {
  const navigation = useNavigation();

  const [animation] = useState(new Animated.Value(0));
  const [showCookieBox, setShowCookieBox] = useState(true);
  const [cookieLearnMoreOverlay, setCookieLearnMoreOverlay] = useState(false);
  const [preferencesOverlay, setPreferencesOverlay] = useState(false);
  const [necessaryCookiesCheckbox, setNecessaryCookiesCheckbox] =
    useState(true);
  const [functionalityCookiesCheckbox, setFunctionalityCookiesCheckbox] =
    useState(true);
  const [experienceCookiesCheckbox, setExperienceCookiesCheckbox] =
    useState(true);
  const [measurementCookiesCheckbox, setMeasurementCookiesCheckbox] =
    useState(true);
  const [marketingCookiesCheckbox, setMarketingCookiesCheckbox] =
    useState(true);
  const [necessaryDetails, setNecessaryDetails] = useState(false);
  const [functionalityDetails, setFunctionalityDetails] = useState(false);
  const [experienceDetails, setExperienceDetails] = useState(false);
  const [measurementDetails, setMeasurementDetails] = useState(false);
  const [marketingDetails, setMarketingDetails] = useState(false);

  useEffect(() => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();

    AsyncStorage.getData("selectedCookies").then((value) => {
      if (!value) {
        setShowCookieBox(true);
      } else {
        setShowCookieBox(false);
      }
    });
  }, []);

  const { width } = Dimensions.get("window");

  const handleAccept = () => {
    Animated.timing(animation, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => setShowCookieBox(false));

    const selectedCookies = [
      { id: 1, cookieType: "necessary", isSelected: necessaryCookiesCheckbox },
      {
        id: 2,
        cookieType: "functionality",
        isSelected: functionalityCookiesCheckbox,
      },
      {
        id: 3,
        cookieType: "experience",
        isSelected: experienceCookiesCheckbox,
      },
      {
        id: 4,
        cookieType: "measurement",
        isSelected: measurementCookiesCheckbox,
      },
      { id: 5, cookieType: "marketing", isSelected: marketingCookiesCheckbox },
    ];

    console.log("selectedCookies :", selectedCookies);

    AsyncStorage.storeData("selectedCookies", JSON.stringify(selectedCookies));
  };

  const translateY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [100, 0],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  if (!showCookieBox) {
    return null;
  }

  return (
    <>
      {width > 768 && (
        <Animated.View
          style={[styles.container, { transform: [{ translateY }], opacity }]}
          className="flex flex-row justify-center items-center w-full"
        >
          <View className="flex flex-row">
            <View>
              <Text className="mx-10 text-lg font-Crimson">
                This website uses cookies to ensure you get the best experience
                on our website.
              </Text>
            </View>

            <View>
              <TouchableOpacity
                onPress={() => {
                  setCookieLearnMoreOverlay(true);
                }}
              >
                <Text className="text-lg color-[#111111] underline mr-3 font-Crimson">
                  Learn More
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <View className="flex flex-row justify-start items-start mr-3">
            <TouchableOpacity
              className="w-auto h-10 max-h-10 bg-gray-400 mr-3 px-4 rounded-sm"
              onPress={() => {
                setPreferencesOverlay(true);
              }}
            >
              <Text className="text-white w-[80px] h-10 leading-10 text-center  font-PingFangSC">
                Preference
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              className="w-auto h-10 max-h-10 bg-brandgold mr-3 px-4 rounded-sm"
              onPress={() => {
                handleAccept();
              }}
            >
              <Text className="text-white w-[80px] h-10 leading-10 text-center font-PingFangSC">
                Accept
              </Text>
            </TouchableOpacity>
          </View>

          <Modal
            animationType="slide"
            visible={cookieLearnMoreOverlay}
            transparent={true}
          >
            <View
              className="h-screen top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
              // style={{ position: "fixed" }}
            >
              <View className="w-11/12 max-w-lg bg-white px-6 py-10 relative">
                <TouchableOpacity
                  className="absolute right-1 top-1"
                  onPress={() => {
                    setCookieLearnMoreOverlay(false);
                  }}
                >
                  <Image
                    className="w-5 h-5"
                    source={require("../assets/btn-close.png")}
                  />
                </TouchableOpacity>

                <View className="px-3">
                  <Text className="text-xl font-semibold font-Crimson my-2">
                    Our use of cookies :
                  </Text>

                  <Text className="font-normal text-[#86909C] text-start py-3 font-Crimson">
                    We use essential cookies to make our site work. With your
                    consent, we may also use non-essential cookies to improve
                    user experience and analyze website traffic. By clicking
                    “Accept,“ you agree to our website's cookie use as described
                    in our Cookie Policy. You can change your cookie settings at
                    any time by clicking “Preferences.
                  </Text>
                </View>

                <View className="text-right flex items-end py-3">
                  <TouchableOpacity
                    className="w-full h-8 max-h-8 bg-brandgold mt-4 rounded-sm"
                    onPress={() => {
                      setCookieLearnMoreOverlay(false);
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="slide"
            visible={preferencesOverlay}
            transparent={true}
          >
            <View className="h-screen top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]">
              <View className="w-11/12 max-w-lg max-h-[600px] bg-white px-0 py-5 relative">
                <TouchableOpacity
                  className="absolute right-1 top-1"
                  onPress={() => {
                    setPreferencesOverlay(false);
                  }}
                >
                  <Image
                    className="w-5 h-5"
                    source={require("../assets/btn-close.png")}
                  />
                </TouchableOpacity>

                <ScrollView className="w-full flex-1 h-auto md:mt-4">
                  <View className="px-8 py-3">
                    <Text className="text-xl font-semibold font-Crimson">
                      Your Cookie Preferences
                    </Text>

                    <Text className="font-normal text-[#86909C] py-4 font-Crimson">
                      We use different types of cookies to optimize your
                      experience on our website. Click on the categories below
                      to learn more about their purposes. You may choose which
                      types of cookies to allow and can change your preferences
                      at any time. Remember that disabling cookies may affect
                      your experience on the website.
                    </Text>

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={necessaryCookiesCheckbox}
                        onValueChange={setNecessaryCookiesCheckbox}
                        color={necessaryCookiesCheckbox ? "#000000" : undefined}
                        disabled={true}
                      />

                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          setNecessaryCookiesCheckbox(necessaryCookiesCheckbox)
                        }
                      >
                        Necessary
                      </Text>

                      {necessaryDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setNecessaryDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {necessaryDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setNecessaryDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {necessaryDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies are necessary to the core functionality of
                        our website and some of its features, such as access to
                        secure areas.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={functionalityCookiesCheckbox}
                        onValueChange={setFunctionalityCookiesCheckbox}
                        color={
                          functionalityCookiesCheckbox ? "#000000" : undefined
                        }
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setFunctionalityCookiesCheckbox }(
                            functionalityCookiesCheckbox
                          ))
                        }
                      >
                        Functionality
                      </Text>

                      {functionalityDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setFunctionalityDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {functionalityDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setFunctionalityDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {functionalityDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies enable the website owner to track and
                        analyze the usage of the website to improve its
                        performance and user experience.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={experienceCookiesCheckbox}
                        onValueChange={setExperienceCookiesCheckbox}
                        color={
                          experienceCookiesCheckbox ? "#000000" : undefined
                        }
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setExperienceCookiesCheckbox }(
                            experienceCookiesCheckbox
                          ))
                        }
                      >
                        Experience
                      </Text>

                      {experienceDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setExperienceDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {experienceDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setExperienceDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {experienceDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies are used to show users personalized ads
                        based on their browsing behavior and interests.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={measurementCookiesCheckbox}
                        onValueChange={setMeasurementCookiesCheckbox}
                        color={
                          measurementCookiesCheckbox ? "#000000" : undefined
                        }
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setMeasurementCookiesCheckbox }(
                            measurementCookiesCheckbox
                          ))
                        }
                      >
                        Measurement
                      </Text>

                      {measurementDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMeasurementDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {measurementDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMeasurementDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {measurementDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies allow users to share content from the
                        website on their social media profiles or interact with
                        social media widgets on the website.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={marketingCookiesCheckbox}
                        onValueChange={setMarketingCookiesCheckbox}
                        color={marketingCookiesCheckbox ? "#000000" : undefined}
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setMarketingCookiesCheckbox }(
                            marketingCookiesCheckbox
                          ))
                        }
                      >
                        Marketing
                      </Text>

                      {marketingDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMarketingDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {marketingDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMarketingDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {marketingDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies are used to remember user preferences and
                        settings, such as language, font size, and layout.
                      </Text>
                    )}
                  </View>
                </ScrollView>

                <View className="flex flex-row items-center justify-evenly mb-3">
                  <TouchableOpacity
                    className="w-[35%] h-8 max-h-8 bg-gray-400 mt-4"
                    onPress={() => {
                      setFunctionalityCookiesCheckbox(false);
                      setExperienceCookiesCheckbox(false);
                      setMeasurementCookiesCheckbox(false);
                      setMarketingCookiesCheckbox(false);
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Decline All
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    className="w-[35%] h-8 max-h-8 bg-brandgold mt-4"
                    onPress={() => {
                      setFunctionalityCookiesCheckbox(true);
                      setExperienceCookiesCheckbox(true);
                      setMeasurementCookiesCheckbox(true);
                      setMarketingCookiesCheckbox(true);
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Allow All
                    </Text>
                  </TouchableOpacity>
                </View>

                <View className="flex flex-row items-center justify-evenly mb-6">
                  <TouchableOpacity
                    className="w-[35%] h-8 max-h-8 bg-brandgold mt-4"
                    onPress={() => {
                      handleAccept();
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Save and Accept
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </Animated.View>
      )}
      {width <= 768 && (
        <Animated.View
          style={[
            styles.mobileContainer,
            { transform: [{ translateY }], opacity },
          ]}
          className="flex flex-row justify-center items-center w-full"
        >
          <View className="flex flex-col flex-wrap">
            <View className="flex flex-row flex-wrap w-[350px] whitespace-normal">
              <Text className="text-lg font-Crimson ">
                This website uses cookies to ensure you get the best experience
                on our website.
              </Text>

              <View className="my-3">
                <TouchableOpacity
                  onPress={() => {
                    setCookieLearnMoreOverlay(true);
                  }}
                >
                  <Text className="text-sm color-[#111111] underline mr-3 font-Crimson">
                    Learn More
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <View className="flex flex-row justify-center items-center mt-4 mr-3">
              <TouchableOpacity
                className="w-auto h-10 max-h-10 bg-gray-400 mr-3 px-4 rounded-sm"
                onPress={() => {
                  setPreferencesOverlay(true);
                }}
              >
                <Text className="text-white w-[80px] h-10 leading-10 text-center  font-PingFangSC">
                  Preference
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                className="w-auto h-10 max-h-10 bg-brandgold mr-3 px-4 rounded-sm"
                onPress={() => {
                  handleAccept();
                }}
              >
                <Text className="text-white w-[80px] h-10 leading-10 text-center font-PingFangSC">
                  Accept
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <Modal
            animationType="slide"
            visible={cookieLearnMoreOverlay}
            transparent={true}
          >
            <View
              className="h-screen top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
              // style={{ position: "fixed" }}
            >
              <View className="w-11/12 max-w-lg bg-white px-6 py-10 relative">
                <TouchableOpacity
                  className="absolute right-1 top-1"
                  onPress={() => {
                    setCookieLearnMoreOverlay(false);
                  }}
                >
                  <Image
                    className="w-5 h-5"
                    source={require("../assets/btn-close.png")}
                  />
                </TouchableOpacity>

                <View className="px-3">
                  <Text className="text-xl font-semibold font-Crimson my-2">
                    Our use of cookies :
                  </Text>

                  <Text className="font-normal text-[#86909C] text-start py-3 font-Crimson">
                    We use essential cookies to make our site work. With your
                    consent, we may also use non-essential cookies to improve
                    user experience and analyze website traffic. By clicking
                    “Accept,“ you agree to our website's cookie use as described
                    in our Cookie Policy. You can change your cookie settings at
                    any time by clicking “Preferences.
                  </Text>
                </View>

                <View className="text-right flex items-end py-3">
                  <TouchableOpacity
                    className="w-full h-8 max-h-8 bg-brandgold mt-4 rounded-sm"
                    onPress={() => {
                      setCookieLearnMoreOverlay(false);
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="slide"
            visible={preferencesOverlay}
            transparent={true}
          >
            <View className="h-screen top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]">
              <View className="w-11/12 max-w-lg max-h-[600px] bg-white px-0 py-5 relative">
                <TouchableOpacity
                  className="absolute right-1 top-1"
                  onPress={() => {
                    setPreferencesOverlay(false);
                  }}
                >
                  <Image
                    className="w-5 h-5"
                    source={require("../assets/btn-close.png")}
                  />
                </TouchableOpacity>

                <ScrollView className="w-full flex-1 h-auto md:mt-4">
                  <View className="px-8 py-3">
                    <Text className="text-xl font-semibold font-Crimson">
                      Your Cookie Preferences
                    </Text>

                    <Text className="font-normal text-[#86909C] py-4 font-Crimson">
                      We use different types of cookies to optimize your
                      experience on our website. Click on the categories below
                      to learn more about their purposes. You may choose which
                      types of cookies to allow and can change your preferences
                      at any time. Remember that disabling cookies may affect
                      your experience on the website.
                    </Text>

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={necessaryCookiesCheckbox}
                        onValueChange={setNecessaryCookiesCheckbox}
                        color={necessaryCookiesCheckbox ? "#000000" : undefined}
                        disabled={true}
                      />

                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          setNecessaryCookiesCheckbox(necessaryCookiesCheckbox)
                        }
                      >
                        Necessary
                      </Text>

                      {necessaryDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setNecessaryDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {necessaryDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setNecessaryDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {necessaryDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies are necessary to the core functionality of
                        our website and some of its features, such as access to
                        secure areas.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={functionalityCookiesCheckbox}
                        onValueChange={setFunctionalityCookiesCheckbox}
                        color={
                          functionalityCookiesCheckbox ? "#000000" : undefined
                        }
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setFunctionalityCookiesCheckbox }(
                            functionalityCookiesCheckbox
                          ))
                        }
                      >
                        Functionality
                      </Text>

                      {functionalityDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setFunctionalityDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {functionalityDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setFunctionalityDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {functionalityDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies enable the website owner to track and
                        analyze the usage of the website to improve its
                        performance and user experience.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={experienceCookiesCheckbox}
                        onValueChange={setExperienceCookiesCheckbox}
                        color={
                          experienceCookiesCheckbox ? "#000000" : undefined
                        }
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setExperienceCookiesCheckbox }(
                            experienceCookiesCheckbox
                          ))
                        }
                      >
                        Experience
                      </Text>

                      {experienceDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setExperienceDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {experienceDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setExperienceDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {experienceDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies are used to show users personalized ads
                        based on their browsing behavior and interests.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={measurementCookiesCheckbox}
                        onValueChange={setMeasurementCookiesCheckbox}
                        color={
                          measurementCookiesCheckbox ? "#000000" : undefined
                        }
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setMeasurementCookiesCheckbox }(
                            measurementCookiesCheckbox
                          ))
                        }
                      >
                        Measurement
                      </Text>

                      {measurementDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMeasurementDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {measurementDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMeasurementDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {measurementDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies allow users to share content from the
                        website on their social media profiles or interact with
                        social media widgets on the website.
                      </Text>
                    )}

                    <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                    <View className="flex-row items-start mt-4 mb-1">
                      <Checkbox
                        className="mr-3"
                        value={marketingCookiesCheckbox}
                        onValueChange={setMarketingCookiesCheckbox}
                        color={marketingCookiesCheckbox ? "#000000" : undefined}
                      />
                      <Text
                        className="font-semibold font-Crimson"
                        onPress={() =>
                          (onValueChange = { setMarketingCookiesCheckbox }(
                            marketingCookiesCheckbox
                          ))
                        }
                      >
                        Marketing
                      </Text>

                      {marketingDetails == false && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMarketingDetails(true)}
                        >
                          See description
                        </Text>
                      )}

                      {marketingDetails == true && (
                        <Text
                          className="text-gray-500 font-Crimson underline ml-3"
                          onPress={() => setMarketingDetails(false)}
                        >
                          Hide description
                        </Text>
                      )}
                    </View>

                    {marketingDetails && (
                      <Text className="text-normal font-normal text-[#86909C] text-start py-2 font-Crimson">
                        These cookies are used to remember user preferences and
                        settings, such as language, font size, and layout.
                      </Text>
                    )}
                  </View>
                </ScrollView>

                <View className="flex flex-row items-center justify-evenly mb-3">
                  <TouchableOpacity
                    className="w-[35%] h-8 max-h-8 bg-gray-400 mt-4"
                    onPress={() => {
                      setFunctionalityCookiesCheckbox(false);
                      setExperienceCookiesCheckbox(false);
                      setMeasurementCookiesCheckbox(false);
                      setMarketingCookiesCheckbox(false);
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Decline All
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    className="w-[35%] h-8 max-h-8 bg-brandgold mt-4"
                    onPress={() => {
                      setFunctionalityCookiesCheckbox(true);
                      setExperienceCookiesCheckbox(true);
                      setMeasurementCookiesCheckbox(true);
                      setMarketingCookiesCheckbox(true);
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Allow All
                    </Text>
                  </TouchableOpacity>
                </View>

                <View className="flex flex-row items-center justify-evenly mb-6">
                  <TouchableOpacity
                    className="w-[35%] h-8 max-h-8 bg-brandgold mt-4"
                    onPress={() => {
                      handleAccept();
                    }}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      Save and Accept
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </Animated.View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 70,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    flexDirection: "row",
  },
  mobileContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: 230,
    padding: 20,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CookieConsent;
